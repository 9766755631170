import { Menu } from "src/app/_models/data-store/menu";

export interface MenuState
{
    menus: Menu[] | null;
    selectedMenu: Menu | null;
    selectedMenuId: string | null;
    menu: Menu | null;
    loading: boolean;
    error: any;
}

export const initialMenuState: MenuState = {
    menus: null,
    selectedMenu: null,
    selectedMenuId: null,
    menu: null,
    loading: false,
    error: null
};