import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError, tap } from 'rxjs/operators';
import { DeviceService } from '../../../_services/identifiers/device.service';
import { DeviceActionTypes, SaveDevice, SaveDeviceSuccess, SaveDeviceFailure, DeleteDevice, DeleteDeviceSuccess, DeleteDeviceFailure, LoadDevicesFailure, LoadDeviceById, LoadDeviceByIdFailure, LoadDeviceByIdSuccess, LoadDevices, LoadDevicesSuccess, ScrollToTopAction, LoadDeviceByFingerprint, LoadDeviceByFingerprintFailure, LoadDeviceByFingerprintSuccess } from './../actions/device.actions';

@Injectable()
export class DeviceEffects
{
    constructor(private actions$: Actions, private backendService: DeviceService, private router: Router) 
    {

    }

    loadDevices$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadDevices>(DeviceActionTypes.LoadDevices),
            mergeMap(action =>
                this.backendService.getDevices(action.payload.bypassCache).pipe(
                    map(devices => new LoadDevicesSuccess({ devices, bypassCache: action.payload.bypassCache })),
                    catchError(error => of(new LoadDevicesFailure({ error })))
                )
            )
        )
    );

    loadDeviceById$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadDeviceById>(DeviceActionTypes.LoadDeviceById),
            mergeMap(action =>
                this.backendService.getDeviceById(action.payload.deviceId, action.payload.bypassCache).pipe(
                    map(device => new LoadDeviceByIdSuccess({ device, bypassCache: action.payload.bypassCache })),
                    catchError(error => of(new LoadDeviceByIdFailure({ error })))
                )
            )
        )
    );

    loadDeviceByFingerprint$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadDeviceByFingerprint>(DeviceActionTypes.LoadDeviceByFingerprint),
            mergeMap(action =>
                this.backendService.getDeviceByFingerprint(action.payload.fingerprint, action.payload.bypassCache).pipe(
                    map(device => new LoadDeviceByFingerprintSuccess({ device, bypassCache: action.payload.bypassCache })),
                    catchError(error => of(new LoadDeviceByFingerprintFailure({ error })))
                )
            )
        )
    );

    loadDevicesFailure$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadDeviceByFingerprintFailure>(DeviceActionTypes.LoadDeviceByFingerprintFailure),
            tap(() => this.router.navigate(['/login']))
        ),
        { dispatch: false } // This effect does not dispatch a new action
    );

    saveDevice$ = createEffect(() =>
        this.actions$.pipe(
            ofType<SaveDevice>(DeviceActionTypes.SaveDevice),
            mergeMap(action =>
                this.backendService.saveDevice(action.payload.device, action.payload.bypassCache).pipe(
                    map(device => new SaveDeviceSuccess({ device, bypassCache: action.payload.bypassCache })),
                    catchError(error => of(new SaveDeviceFailure({ error })))
                )
            )
        )
    );

    // Effect to handle SaveDeviceSuccess and dispatch LoadDevices
    saveDataSuccessLoadDevices$ = createEffect(() =>
        this.actions$.pipe(
            ofType<SaveDeviceSuccess>(DeviceActionTypes.SaveDeviceSuccess),
            map(action => new LoadDevices({ bypassCache: action.payload.bypassCache }))
        )
    );

    saveDataSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DeviceActionTypes.SaveDeviceSuccess),
            map(action => new LoadDeviceByFingerprint({ fingerprint: (<any>action)?.Fingerprint, bypassCache: true }))
        )
    );

    deleteDevice$ = createEffect(() =>
        this.actions$.pipe(
            ofType<DeleteDevice>(DeviceActionTypes.DeleteDevice),
            mergeMap(action =>
                this.backendService.deleteDevice(action.payload.deviceId, action.payload.bypassCache).pipe(
                    map(() => new DeleteDeviceSuccess()),
                    catchError(error => of(new DeleteDeviceFailure({ error })))
                )
            )
        )
    );

    // Ensure that all your effect functions return Observables of Actions

    scrollToTop$ = createEffect(() =>
        this.actions$.pipe(
            ofType<SaveDeviceSuccess>(DeviceActionTypes.SaveDeviceSuccess),
            map(() => new ScrollToTopAction())
        )
    );
}