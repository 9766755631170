import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms'; // Import ReactiveFormsModule

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { ErrorInterceptor } from './_interceptors/error.interceptor';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { DatePipe } from '@angular/common';

import { Device } from "@awesome-cordova-plugins/device/ngx";
import { DeviceInfoService } from './_services/identifiers/device-info.service';

import { SharedModule } from './_shared/shared.module';
import { HttpConfigInterceptor } from './_interceptors/http-config.interceptor';
import { ApiService } from './_services/api.service';
import { AuthInterceptor } from './_interceptors/auth.interceptor';

//ngrx
import { deviceReducer } from './_ngrx-store/device/reducers/device.reducers';
import { DeviceEffects } from './_ngrx-store/device/effects/device.effects';
import { menuReducer } from './_ngrx-store/menu/reducers/menu.reducers';
import { MenuEffects } from './_ngrx-store/menu/effects/menu.effects';
import { ComponentService } from './_services/component.service';
import { popReducer } from './_ngrx-store/extended/configurations/pop/reducers/pop.reducers';
import { PopEffects } from './_ngrx-store/extended/configurations/pop/effects/pop.effects';
import { messageReducer } from './_ngrx-store/extended/messages/reducers/message.reducers';
import { MessageEffects } from './_ngrx-store/extended/messages/effects/message.effects';
import { imapReducer } from './_ngrx-store/extended/configurations/imap/reducers/imap.reducers';
import { ImapEffects } from './_ngrx-store/extended/configurations/imap/effects/imap.effects';


@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        SharedModule,

        StoreModule.forRoot({}),
        StoreModule.forFeature('device', deviceReducer),
        StoreModule.forFeature('menu', menuReducer),
        StoreModule.forFeature('pop', popReducer),
        StoreModule.forFeature('imap', imapReducer),
        StoreModule.forFeature('message', messageReducer),
        // StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
        StoreDevtoolsModule.instrument({ /* configuration options */ }),
        EffectsModule.forRoot([DeviceEffects, MenuEffects, PopEffects, ImapEffects, MessageEffects]),
    ],
    providers: [
        provideHttpClient(withInterceptorsFromDi()),
        {
            provide: RouteReuseStrategy,
            useClass: IonicRouteStrategy
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpConfigInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        DatePipe,
        ApiService,
        Device,
        DeviceInfoService,
        ComponentService
    ],
    bootstrap: [
        AppComponent
    ],
})
export class AppModule { }
