import { Imap } from "src/app/_models/data-store/extended/configurations/imap";

export interface ImapState
{
    imaps: Imap[] | null;
    selectedImap: Imap | null;
    selectedImapId: string | null;
    imap: Imap | null;
    loading: boolean;
    error: any;
}

export const initialImapState: ImapState = {
    imaps: null,
    selectedImap: null,
    selectedImapId: null,
    imap: null,
    loading: false,
    error: null
};