import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class LoadingService
{
    private isLoading = false;
    private static readonly ionLoadingElementName: string = "ion-loading";
    private static readonly loadingControllerId: string = "spoon-indicator-1";
    private loaders: any[] = [];

    constructor(public loadingController: LoadingController) 
    {

    }

    public show(duration?: number)
    {
        const element = window.document.getElementsByTagName(LoadingService.ionLoadingElementName);
        const elementExists: boolean = element != null && element.length > 0;

        if (elementExists)
            return;

        if (!duration || duration == null)
            duration = (60 * 5) * 1000;

        this.loadingController.create({
            id: LoadingService.loadingControllerId,
            message: 'Please wait ...',
            spinner: 'crescent',
            translucent: true,
            duration: duration,
            showBackdrop: true,
            backdropDismiss: true
        }).then((loader) => 
        {
            loader.present();
            this.loaders.push("");
        });
    }

    public hide()
    {
        const element = window.document.getElementsByTagName(LoadingService.ionLoadingElementName);
        const elementExists: boolean = element != null && element.length > 0;

        if (!elementExists)
            return;

        for (let i = 0; i < element.length; i++)
            this.loadingController
                .dismiss(null, undefined, LoadingService.loadingControllerId)
                .catch((err) => 
                {
                    this.remove(element);
                })
                .then((loader) => 
                {
                    this.remove(element);
                });
    }

    private remove(element: any)
    {
        this.loaders.pop();

        if (this.loaders.length === 0)
            if (element && element.item(0))
                element.item(0).remove();
    }
}
