import { Component, Input, ViewChild, OnInit, OnDestroy } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { Subject } from "rxjs";
import { ComponentHostDirective } from "src/app/_directives/component.service";
import { ComponentService } from "src/app/_services/component.service";

@Component({
    selector: "app-modal-template",
    templateUrl: "./detail.component.html",
    providers: [
        //all internal/related services
    ]
})

export class ModalTemplateComponent implements OnInit, OnDestroy
{
    @Input() data: any;
    @Input() showHeader: boolean = true;
    @Input() showFooter: boolean = false;
    @Input() title: string | null = null;
    @ViewChild(ComponentHostDirective, { static: true }) componentHost: ComponentHostDirective | null = null;

    private destroySubject = new Subject();

    constructor(private modalController: ModalController, private componentService: ComponentService)
    {

    }

    dismiss()
    {
        this.modalController.dismiss({ 'dismissed': true });
    }

    ngOnInit()
    {
        const viewContainerRef = this.componentHost?.viewContainerRef;

        if (viewContainerRef)
            this.componentService.loadComponent(viewContainerRef, this.data.componentRef, this.data);
    }

    ngOnDestroy()
    {
        this.destroySubject.next(null);
        this.destroySubject.complete();
    }
}