import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PageStateService
{
    private currentPageSubject = new BehaviorSubject<string>('');
    currentPage$ = this.currentPageSubject.asObservable();

    setCurrentPage(page: string)
    {
        this.currentPageSubject.next(page);
    }

    getCurrentPage(): string
    {
        return this.currentPageSubject.getValue();
    }

    shouldSkipRequest(allowedPages: string[], forceRequest: boolean = false): Observable<boolean>
    {
        return this.currentPage$.pipe(map(currentPage =>
        {
            if (forceRequest)
                return false;

            return allowedPages.includes(currentPage);
        }));
    }
}
