import { MenuActions, MenuActionTypes } from "./../actions/menu.actions";
import { initialMenuState, MenuState } from "../states/menu.states";
import { Utility } from '../../../_shared/utils';

export function menuReducer(state = initialMenuState, action: MenuActions): MenuState
{
    switch (action.type)
    {
        case MenuActionTypes.SelectMenu:
            return {
                ...state,
                selectedMenuId: action.payload.menuId, // Or selectedMenu: action.payload.menu
            };
        case MenuActionTypes.DeselectMenu:
            return {
                ...state,
                selectedMenuId: null, // Or selectedMenu: null
            };

        case MenuActionTypes.LoadMenus:
        case MenuActionTypes.LoadMenuById: {
            return {
                ...state,
                loading: true,
                error: null
            };
        }
        case MenuActionTypes.LoadMenusSuccess: {
            return {
                ...state,
                menus: action.payload.menus,
                loading: false
            };
        }
        case MenuActionTypes.LoadMenuByIdSuccess: {
            return {
                ...state,
                selectedMenu: action.payload.menu,
                menu: Utility.detectAndConvert(action.payload.menu),
                loading: false
            };
        }
        case MenuActionTypes.LoadMenusFailure:
        case MenuActionTypes.LoadMenuByIdFailure: {
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        }

        case MenuActionTypes.LoadMenu:
        case MenuActionTypes.SaveMenu:
        case MenuActionTypes.DeleteMenu: {
            return {
                ...state,
                loading: true,
                error: null
            };
        }
        case MenuActionTypes.LoadMenuSuccess:
        case MenuActionTypes.SaveMenuSuccess: {
            return {
                ...state,
                menu: action.payload.menu,
                loading: false
            };
        }
        case MenuActionTypes.LoadMenuFailure:
        case MenuActionTypes.SaveMenuFailure:
        case MenuActionTypes.DeleteMenuFailure: {
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        }
        case MenuActionTypes.DeleteMenuSuccess: {
            return {
                ...state,
                menu: null,
                loading: false
            };
        }
        case MenuActionTypes.ClearMenu:
            return {
                ...state,
                loading: false,
                error: null
            };
        default:
            return state;
    }
}