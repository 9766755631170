import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestEventType } from '../../../../../_models/core/data/dtos/events/request-type.model';
import { ApiService } from './../../../../api.service';
import { ApiHttpOptions } from '../../../../../_models/core/api-http-options.model';
import { BaseRequestEvent } from '../../../../../_models/core/data/dtos/events/base-request-event.model';
import { Imap } from '../../../../../_models/data-store/extended/configurations/imap';

@Injectable({
    providedIn: 'root'
})
export class ImapService
{
    private readonly requestedType: string = "v1.DataStore.Mail.Configurations.Imap"

    constructor(private apiService: ApiService)
    {

    }

    getImaps(applicationId: string, clientId: string, deviceId: string, bypassCache: boolean = false): Observable<any[]>
    {
        const options = new ApiHttpOptions();

        let query: string = "1=1";

        // Add conditions based on the parameters provided
        if (applicationId)
            query += ` AND applicationId = \"${applicationId}\"`;

        if (clientId)
            query += ` AND clientId = \"${clientId}\"`;

        //todo later if needed - imap per device
        // if (deviceId)
        //     query += ` AND deviceid = \"${deviceId}\"`;

        query = query.replace("1=1 AND ", "");

        options.method = "GET";
        options.requestedType = this.requestedType;
        options.requestEvent = <BaseRequestEvent>{ getAll: true, where: query };
        options.bypassCache = bypassCache;

        return this.apiService.runWithOptions(options);
    }

    getImap(applicationId: string | null, clientId: string | null, deviceId: string | null, sectionId: number | null, bypassCache: boolean = false, enableLoading: boolean = false): Observable<Imap[]>
    {
        const options = new ApiHttpOptions();

        let query: string = "1=1";

        if (applicationId)
            query += ` AND applicationid = \"${applicationId}\"`;

        if (clientId)
            query += ` AND clientid = \"${clientId}\"`;

        //todo later if needed - imap per device
        // if (deviceId)
        //     query += ` AND deviceid = \"${deviceId}\"`;

        if (sectionId !== null)
            query += ` AND sectionid = ${sectionId}`;

        query = query.replace("1=1 AND ", "");

        options.method = "GET";
        options.requestedType = this.requestedType;
        options.requestEvent = <BaseRequestEvent>{ getAll: true, where: query };
        options.bypassCache = bypassCache;
        options.enableLoading = enableLoading;

        return this.apiService.runWithOptions(options);
    }

    getImapById(id: string, bypassCache: boolean = false): Observable<any>
    {
        const options = new ApiHttpOptions();

        options.method = "GET";
        options.requestedType = this.requestedType;
        options.requestEvent = <BaseRequestEvent>{ id: id };
        options.bypassCache = bypassCache;

        return this.apiService.runWithOptions(options);
    }

    saveImap(model: any, bypassCache: boolean = true): Observable<any>
    {
        const options = new ApiHttpOptions();

        options.method = "POST";
        options.requestedType = this.requestedType;
        options.requestEvent = <BaseRequestEvent>{ method: RequestEventType.Post, payload: model };
        options.bypassCache = bypassCache;

        return this.apiService.runWithOptions(options);
    }

    deleteImap(imapId: string, bypassCache: boolean = true): Observable<any>
    {
        const options = new ApiHttpOptions();

        options.method = "DELETE";
        options.requestedType = this.requestedType;
        options.requestEvent = <BaseRequestEvent>{ method: RequestEventType.Delete, id: imapId };
        options.bypassCache = bypassCache;

        return this.apiService.runWithOptions(options);
    }
}
