import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Observable, switchMap, of, catchError, throwError, Subject, interval, takeUntil, BehaviorSubject } from 'rxjs';
import { AppConfig } from '../../_configuration/configuration';
import { displayLoading } from '../../_interceptors/http-config.interceptor';
import { decodeToken, isTokenExpired } from '../../_utils/jwt.utils';

const config = AppConfig.getInstance();

export enum UserStatus
{
    NotAvailable = 0,
    LoggedIn = 1,
    LoggedOut = 2
}

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService implements OnDestroy
{
    private readonly tokenKey: string = "token";
    private readonly accessTokenKey: string = "access_token";
    private readonly refreshTokenKey: string = "refresh_token";
    public static readonly applicationIdKey: string = "application_id";
    public static readonly clientIdKey: string = "client_id";
    public static readonly deviceIdKey: string = "device_id";
    private readonly emailKey: string = "email";

    private tokenRefreshInterval = 15 * 60 * 1000; // 15 minutes
    private stopRefresh$ = new Subject<void>();
    private loggedIn$ = new BehaviorSubject<boolean>(this.hasToken());

    public rememberMeKey: string = "PayLocalLogin";

    constructor(private navController: NavController, private http: HttpClient)
    {

    }

    // Check if the token exists in local storage
    private hasToken(): boolean
    {
        return !!localStorage.getItem(this.accessTokenKey);
    }

    // Observable for login state
    get isLoggedIn$()
    {
        return this.loggedIn$.asObservable();
    }

    private getItem(key: string)
    {
        if (key == null || key === "")
            return null;

        const item: string | null = localStorage.getItem(key);

        if (!item || item === null || item === "undefined")
            return null;

        return item;
    }

    public getAuthToken()
    {
        const token: string | null = this.getItem(this.accessTokenKey);

        if (!token || token === "")
            return localStorage.getItem(this.refreshTokenKey);

        return token;
    }

    public setAuthToken(token: any)
    {
        let setIsLoggedIn: boolean = false;

        if (token)
            localStorage.setItem(this.tokenKey, JSON.stringify(token));

        if (token?.access_token)
        {
            localStorage.setItem(this.accessTokenKey, token.access_token);

            setIsLoggedIn = true;
        }

        if (token?.refresh_token)
        {
            localStorage.setItem(this.refreshTokenKey, token.refresh_token);

            setIsLoggedIn = true;
        }

        this.loggedIn$.next(setIsLoggedIn);
    }

    public removeAuthToken()
    {
        const accessToken: string | null = this.getItem(this.accessTokenKey);

        if (accessToken !== null && accessToken !== "")
            localStorage.removeItem(this.accessTokenKey);

        const refreshToken: string | null = this.getItem(this.refreshTokenKey);

        if (refreshToken !== null && refreshToken !== "")
            localStorage.removeItem(this.refreshTokenKey);
    }

    public getApplicationId()
    {
        return this.getItem(AuthenticationService.applicationIdKey);
    }

    public setApplicationId(applicationId: string)
    {
        if (applicationId)
            localStorage.setItem(AuthenticationService.applicationIdKey, applicationId);
    }

    public removeApplicationId()
    {
        localStorage.removeItem(AuthenticationService.applicationIdKey);
    }

    public getClientId()
    {
        return this.getItem(AuthenticationService.clientIdKey);
    }

    public setClientId(clientId: string)
    {
        if (clientId)
            localStorage.setItem(AuthenticationService.clientIdKey, clientId);
    }

    public removeClientId()
    {
        localStorage.removeItem(AuthenticationService.clientIdKey);
    }

    public getDeviceId()
    {
        return this.getItem(AuthenticationService.deviceIdKey);
    }

    public setDeviceId(deviceId: string)
    {
        if (deviceId)
            localStorage.setItem(AuthenticationService.deviceIdKey, deviceId);
    }

    public removeDeviceId()
    {
        localStorage.removeItem(AuthenticationService.deviceIdKey);
    }

    public getEmail()
    {
        return this.getItem(this.emailKey);
    }

    public setEmail(email: string)
    {
        if (email)
            localStorage.setItem(this.emailKey, email);
    }

    public removeEmail()
    {
        localStorage.removeItem(this.emailKey);
    }

    public isLoggedIn(): boolean
    {
        const token: string | null = this.getItem(this.tokenKey);

        if (token === null || token === "")
            return false;

        return true;
    }

    public isExpired(): boolean
    {
        const token: string | null = this.getItem(this.tokenKey);

        if (token === null || token === "")
            return false;

        const decodedToken: any = decodeToken(token);

        if (!decodedToken)
            return false;

        return !isTokenExpired(decodedToken);
    }

    public success()
    {
        // start the refresh timer
        this.startTokenRefresh();

        this.navController.navigateRoot("/home");
    }

    public logout(redirectToLogin: boolean = true): boolean
    {
        // Clear tokens from storage
        localStorage.removeItem(this.tokenKey);
        localStorage.removeItem(this.accessTokenKey);
        localStorage.removeItem(this.refreshTokenKey);
        localStorage.removeItem(this.emailKey);

        this.stopTokenRefresh();
        this.loggedIn$.next(false);

        if (redirectToLogin)
            this.navController.navigateRoot("/login");

        return true;
    }

    validateToken(redirectToLogin: boolean = false): Observable<boolean>
    {
        const token = this.getAuthToken();

        if (!token || isTokenExpired(decodeToken(token)))
            return this.refreshToken().pipe(
                switchMap(() => of(true)),
                catchError((error) =>
                {
                    this.logout(redirectToLogin);

                    return of(false);
                })
            );

        //when a user already has a token but loads/opens the application
        //start the refresh token timer
        this.startTokenRefresh();

        return of(true);
    }

    refreshToken(): Observable<any>
    {
        let refreshToken = this.getItem(this.refreshTokenKey);

        if (!refreshToken)
        {
            const token = this.getItem(this.tokenKey);

            if (!token)
                return throwError(() => 'No token available');

            const decodedToken = decodeToken(token);

            if (!decodedToken)
                return throwError(() => 'No decoded token available');

            if (!decodedToken?.refresh_token)
                return throwError(() => 'No refresh token available');

            refreshToken = decodedToken.refresh_token;
        }

        return this.http.post<any>(config.webApiBaseUrl + '/v1/iam/auth/refresh-token', { refreshToken }, { context: displayLoading(false) }).pipe(
            switchMap((response: any) =>
            {
                localStorage.setItem(this.accessTokenKey, response.AuthToken.access_token);
                this.loggedIn$.next(true);

                return new Observable(observer =>
                {
                    observer.next(response);
                    observer.complete();
                });
            }),
            catchError(error =>
            {
                this.logout();

                return throwError(() => error);
            })
        );
    }




    // Start the token refresh timer
    startTokenRefresh()
    {
        interval(this.tokenRefreshInterval)
            .pipe
            (
                switchMap(() => this.refreshToken()),
                takeUntil(this.stopRefresh$)
            )
            .subscribe
            (
                {
                    next: (response: any) =>
                    {
                        this.storeNewToken(response.AuthToken.access_token);
                    },
                    error: () =>
                    {
                        this.handleTokenRefreshError();
                    }
                }
            );
    }

    stopTokenRefresh()
    {
        this.stopRefresh$.next();
    }

    private storeNewToken(token: string)
    {
        localStorage.setItem(this.accessTokenKey, token);

        this.loggedIn$.next(true);
    }

    private handleTokenRefreshError()
    {
        this.logout();
    }

    ngOnDestroy()
    {
        this.stopTokenRefresh();
    }
}
