import { Injectable } from '@angular/core';
import { Device } from "@awesome-cordova-plugins/device/ngx";
import { select, Store } from '@ngrx/store';
import { filter, Observable } from 'rxjs';
import { DeviceInfo } from '../../_models/device-info';
import { LoadDeviceByFingerprint, SaveDevice } from '../../_ngrx-store/device/actions/device.actions';
import { AppState } from '../../_ngrx-store/app.state';
import { selectDevice } from '../../_ngrx-store/device/selectors/device.selectors';
import { AuthenticationService } from './../iam/authentication.service';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { UAParser } from 'ua-parser-js';

@Injectable({
    providedIn: 'root'
})
export class DeviceInfoService
{
    public device$: Observable<any> | undefined;
    public fingerprint: string = "";
    public deviceInfoJson: string = "";
    private sessionInitializedKey: string = "sessionInitialized";

    constructor(private store: Store<AppState>, private device: Device, private authenticationService: AuthenticationService)
    {

    }

    async initialize()
    {
        const sessionInitialized: string | null = sessionStorage.getItem(this.sessionInitializedKey);

        if (sessionInitialized != null && sessionInitialized !== "")
            return;

        try
        {
            // Initialize an agent at application startup.
            const fp = await FingerprintJS.load();

            // Get the visitor identifier when you need it.
            const result = await fp.get();

            // This is the visitor identifier:
            const visitorId = result.visitorId;

            // Parse user agent
            const parser = new UAParser();
            const uaResult = parser.getResult();

            // Fetch IP details
            const response = await fetch('https://ipinfo.io/json?token=8adbbc269f8378');
            const ipData = await response.json();

            // Combine data
            const fingerprintData = {
                visitorId: result.visitorId,
                browser: uaResult.browser,
                os: uaResult.os,
                device: uaResult.device,
                cpu: uaResult.cpu,
                ip: ipData.ip,
                ipData: ipData,
                city: ipData.city,
                region: ipData.region,
                country: ipData.country,
                loc: ipData.loc,
                org: ipData.org
            };

            this.fingerprint = visitorId;
            this.deviceInfoJson = JSON.stringify(fingerprintData);

            //try to find an existing device id
            if (this.authenticationService.getDeviceId())
            {
                this.store.dispatch(new LoadDeviceByFingerprint({ fingerprint: this.fingerprint, bypassCache: false }));
                this.device$ = this.store.pipe(select(selectDevice));
            }
            else
            {
                if (this.authenticationService.getApplicationId() && this.authenticationService.getClientId() && this.authenticationService.getEmail())
                {
                    const device: any =
                    {
                        "name": this.authenticationService.getEmail(),
                        "email": this.authenticationService.getEmail(),
                        "fingerprint": this.fingerprint,
                        "isActive": true,
                        "isPublic": true,
                        "applicationId": this.authenticationService.getApplicationId(),
                        "clientId": this.authenticationService.getClientId(),
                        "isTrusted": true,
                        "data": this.deviceInfoJson
                    };

                    this.store.dispatch(new SaveDevice({ device, bypassCache: true }));
                    this.device$ = this.store.pipe(
                        select(selectDevice),
                        filter((device: any) => !!device && device.Fingerprint === this.fingerprint)
                    );
                }
            }

            sessionStorage.setItem(this.sessionInitializedKey, "true");
        }
        catch (e)
        {
            //alert and don't continue
        }
    }

    public async getInfo(): Promise<DeviceInfo>
    {
        await this.initialize();

        return new Promise((resolve, reject) =>
        {
            try
            {
                resolve(new DeviceInfo(this.device, this.fingerprint));
            }
            catch (error)
            {
                reject(error);
            }
        });
    }
}
