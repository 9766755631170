// imap.actions.ts

import { Action } from "@ngrx/store";
import { Imap } from "src/app/_models/data-store/extended/configurations/imap";

export enum ImapActionTypes
{
    SelectImap = '[Imap] Select Imap',
    DeselectImap = '[Imap] Deselect Imap',
    LoadImap = '[Imap] Load Imap',
    LoadImapSuccess = '[Imap] Load Imap Success',
    LoadImapFailure = '[Imap] Load Imap Failure',
    SaveImap = '[Imap] Create Imap',
    SaveImapSuccess = '[Imap] Create Imap Success',
    SaveImapFailure = '[Imap] Create Imap Failure',
    DeleteImap = '[Imap] Delete Imap',
    DeleteImapSuccess = '[Imap] Delete Imap Success',
    DeleteImapFailure = '[Imap] Delete Imap Failure',

    LoadImaps = '[Imap] Load Imaps',
    LoadImapsSuccess = '[Imap] Load Imaps Success',
    LoadImapsFailure = '[Imap] Load Imaps Failure',
    LoadImapById = '[Imap] Load Imap By Id',
    LoadImapByIdSuccess = '[Imap] Load Imap By Id Success',
    LoadImapByIdFailure = '[Imap] Load Imap By Id Failure',

    ScrollToTop = '[Imap] Scroll To Top',
}

export class SelectImap implements Action
{
    readonly type = ImapActionTypes.SelectImap;
    constructor(public payload: { imapId: string }) { } // Or payload: { imap: Imap }
}

export class DeselectImap implements Action
{
    readonly type = ImapActionTypes.DeselectImap;
}

export class LoadImaps implements Action
{
    readonly type = ImapActionTypes.LoadImaps;
    constructor(public payload: { applicationId: string, clientId: string, deviceId: string, bypassCache: boolean }) { }
}

export class LoadImapsSuccess implements Action
{
    readonly type = ImapActionTypes.LoadImapsSuccess;
    constructor(public payload: { imaps: Imap[], bypassCache: boolean }) { }
}

export class LoadImapsFailure implements Action
{
    readonly type = ImapActionTypes.LoadImapsFailure;
    constructor(public payload: { error: any }) { }
}

export class LoadImapById implements Action
{
    readonly type = ImapActionTypes.LoadImapById;
    constructor(public payload: { imapId: string, bypassCache: boolean }) { }
}

export class LoadImapByIdSuccess implements Action
{
    readonly type = ImapActionTypes.LoadImapByIdSuccess;
    constructor(public payload: { imap: Imap, bypassCache: boolean }) { }
}

export class LoadImapByIdFailure implements Action
{
    readonly type = ImapActionTypes.LoadImapByIdFailure;
    constructor(public payload: { error: any }) { }
}

export class LoadImap implements Action
{
    readonly type = ImapActionTypes.LoadImap;
}

export class LoadImapSuccess implements Action
{
    readonly type = ImapActionTypes.LoadImapSuccess;
    constructor(public payload: { imap: Imap, bypassCache: boolean }) { }
}

export class LoadImapFailure implements Action
{
    readonly type = ImapActionTypes.LoadImapFailure;
    constructor(public payload: { error: any }) { }
}

export class SaveImap implements Action
{
    readonly type = ImapActionTypes.SaveImap;
    constructor(public payload: { imap: Imap, applicationId: string, clientId: string, deviceId: string, bypassCache: boolean }) { }
}

export class SaveImapSuccess implements Action
{
    readonly type = ImapActionTypes.SaveImapSuccess;
    constructor(public payload: { imap: Imap, applicationId: string, clientId: string, deviceId: string, bypassCache: boolean }) { }
}

export class SaveImapFailure implements Action
{
    readonly type = ImapActionTypes.SaveImapFailure;
    constructor(public payload: { error: any }) { }
}

export class DeleteImap implements Action
{
    readonly type = ImapActionTypes.DeleteImap;
    constructor(public payload: { imapId: string, bypassCache: boolean }) { }
}

export class DeleteImapSuccess implements Action
{
    readonly type = ImapActionTypes.DeleteImapSuccess;
}

export class DeleteImapFailure implements Action
{
    readonly type = ImapActionTypes.DeleteImapFailure;
    constructor(public payload: { error: any }) { }
}

export class ScrollToTopAction implements Action
{
    readonly type = ImapActionTypes.ScrollToTop;
}

export type ImapActions =
    | LoadImap
    | LoadImapSuccess
    | LoadImapFailure
    | SaveImap
    | SaveImapSuccess
    | SaveImapFailure
    | DeleteImap
    | DeleteImapSuccess
    | DeleteImapFailure
    | LoadImaps
    | LoadImapById
    | LoadImapsSuccess
    | LoadImapByIdSuccess
    | LoadImapsFailure
    | LoadImapByIdFailure
    | SelectImap
    | DeselectImap
    ;
