type AnyObject = { [key: string]: any }; // Define a type alias for any object

//class to handle if an object is an array or object and casts it accordingly
//primarily used in api methods fetching by field where one-to-many results may occur
//or, by default, fetch by id where it is one-to-one result
export class Utility
{
    static detectAndConvert<T extends AnyObject | AnyObject[]>(input: T): T extends AnyObject[] ? AnyObject : T
    {
        if (Array.isArray(input) && input.length === 1)
        {
            return input[0] as T extends AnyObject[] ? AnyObject : T;
        }
        return input as T extends AnyObject[] ? AnyObject : T;
    }
}