import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'emailListDate'
})
export class EmailListDatePipe implements PipeTransform
{
    transform(value: Date | string | number, cultureCode: string = 'en-US'): string
    {
        const date = new Date(value);
        const now = new Date();
        const sixDaysAgo = new Date(now.setDate(now.getDate() - 6));

        // Check if the date is within the last 6 days
        if (date >= sixDaysAgo && date <= new Date())
        {
            if (date.getMonth() === new Date().getMonth() && date.getFullYear() === new Date().getFullYear())
            {
                const weekday = date.toLocaleDateString(cultureCode, { weekday: 'short' });
                const time = date.toLocaleTimeString(cultureCode, {
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true
                });

                return `${weekday}, ${time}`;
            }

            // Return date in default format for dates within the last 6 days but not in the current month
            return date.toLocaleDateString(cultureCode, {
                year: 'numeric',
                month: 'short',
                day: 'numeric'
            });
        }

        // Return default format for dates older than 6 days
        return date.toLocaleDateString(cultureCode, {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        });
    }
}