import { Pipe, PipeTransform, LOCALE_ID, Inject } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
    name: 'customDate'
})
export class CustomDatePipe implements PipeTransform
{
    constructor(@Inject(LOCALE_ID) private locale: string, private datePipe: DatePipe) { }

    transform(value: any, format: string = 'medium', isUTC: boolean = false): any
    {
        if (!value)
            return null;

        if (isUTC)
        {
            // Convert UTC date to local date time
            const localDate = new Date(value + 'Z');

            return this.datePipe.transform(localDate, format, this.locale);
        }
        else
        {
            // Use the provided date value as it is
            return this.datePipe.transform(value, format, this.locale);
        }
    }
}