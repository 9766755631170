import { HttpContext, HttpContextToken, HttpHeaders } from '@angular/common/http';
import { BaseRequestEvent } from './data/dtos/events/base-request-event.model';

export class ApiHttpOptions
{
    applicationId?: string;
    clientId?: string;
    deviceId?: string;
    requestEvent?: BaseRequestEvent;
    relativeUrl?: string;
    version?: string = "v1";
    take: number = 50;
    page: number = 0;
    method: string = "GET";
    response?: any;
    requestedType?: string;
    bypassCache: boolean = false;
    cacheResponseInSeconds: number = 120;
    headers?: HttpHeaders;
    enableLoading: boolean = true;
    forceRequest: boolean = false;

    constructor(applicationId?: string, clientId?: string, deviceId?: string, take?: number, page?: number, bypassCache?: boolean)
    {
        this.applicationId = applicationId;
        this.clientId = clientId;
        this.deviceId = deviceId;
        this.take = take || 50;
        this.page = page || 0;
        this.bypassCache = bypassCache || false;
    }

    get urlPath(): string
    {
        return `${this.version}/${this.relativeUrl ?? ''}`;
    }

    // public Method: string = "GET";
    // public Version: string = "v1";
    // public RelativeUrl?: string;
    // public ByPass: boolean = false;
    // public ResponseType: any = "json"; //"arraybuffer, blob, json, text";
    // public WithCredentials: boolean = false;
    // public Params: any;
    // public Data: any;
    // public Url?: string;
    // public Headers?: HttpHeaders;
    // public AccessToken?: string;
    // public OverridenUrl?: string;
    // public ViewModelRequest:any = null;
    // public Disable400Logging: boolean = false;
    // public Disable404Logging: boolean = false;
    // public Disable500Logging: boolean = false;
    // public DisableScrollToTopAfterResponse: boolean = false;
    // public EnableLoading: boolean = true;
    // public StringifyPayload: boolean = true;
    // public ReturnType: string = "application/pdf";
    // public UseGateway: boolean = true;
    // public UseInterceptor: boolean = true;
}