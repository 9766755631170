<ion-app>
    <ion-menu side="start" menuId="first" contentId="main-content">
        <ion-header>
            <ion-toolbar>
                <ion-title>Menu</ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content>
            <ng-container *ngIf="(appPages$ | async) as menu">
                <ion-list *ngIf="menu?.Items != null && (menu?.Items?.length || 0) > 0">
                    <ion-item *ngFor="let item of menu.Items" [routerLink]="item.Url" (click)="closeMenu()">
                        <ion-icon [name]="item.Icon" slot="start"></ion-icon>
                        <ion-label *ngIf="!item.IsButton">{{ item.Name }}</ion-label>
                        <ion-button *ngIf="item.IsButton" (click)="do(item.ButtonAction)">{{item.Name}}</ion-button>
                    </ion-item>
                </ion-list>
            </ng-container>
        </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
</ion-app>