import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject, EMPTY } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AuthenticationService } from '../_services/iam/authentication.service';
import { ModalService } from '../_services/modal.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor
{
    private isRefreshing = false;
    private refreshingCount: number = 0;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(private router: Router, private authenticationService: AuthenticationService, private modalService: ModalService)
    {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) =>
            {
                if (error instanceof HttpErrorResponse)
                {
                    if (error.status > 400 && error.status < 700)
                    {
                        if (error.status === 401)
                        {
                            // Token expired, handle token refresh
                            return this.handle401Error(req, next);
                        }

                        if (error?.error?.Details)
                            this.modalService.basicAlert(error.error.Details);

                        //page not found
                        if (error.status === 404)
                            this.router.navigate(["/login"]);

                        //user not found
                        if (error.status === 601)
                        {

                        }

                        //invalid login
                        if (error.status === 602)
                            this.router.navigate(["/login"]);

                        return EMPTY;
                    }
                }

                if (error?.error?.Details)
                    this.modalService.basicAlert(error.error.Details);
                else if (error?.error?.Message)
                    this.modalService.basicAlert(error.error.Message);
                else
                    this.modalService.basicAlert(error.message);

                return throwError(() => error);
            })
        );
    }

    private addToken(request: HttpRequest<any>, token: string)
    {
        return request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler)
    {
        if (!this.isRefreshing && this.refreshingCount === 0)
        {
            this.isRefreshing = true;
            this.refreshingCount++;

            this.refreshTokenSubject.next(null);

            return this.authenticationService.refreshToken().pipe(
                switchMap((token: any) =>
                {
                    this.isRefreshing = false;
                    this.refreshingCount = 0;

                    this.refreshTokenSubject.next(token.AuthToken.access_token);

                    return next.handle(this.addToken(request, token.AuthToken.access_token));
                }),
                catchError((error) =>
                {
                    this.isRefreshing = false;
                    this.refreshingCount = 0;

                    this.authenticationService.logout();

                    return throwError(() => error);
                })
            );
        }
        else
        {
            //avoid hanging on this.isRefreshing with counts and logout() in pipe's filter
            this.refreshingCount++;

            if (this.refreshingCount > 1)
                this.authenticationService.logout();

            const t = this;

            return this.refreshTokenSubject.pipe(
                filter(token => t.refreshingCount > 1 ? t.authenticationService.logout() : token != null),
                take(1),
                switchMap(token =>
                {
                    return next.handle(this.addToken(request, token));
                })
            );
        }
    }
}
