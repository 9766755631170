import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestEventType } from '../../_models/core/data/dtos/events/request-type.model';
import { ApiService } from './../api.service';
import { ApiHttpOptions } from '../../_models/core/api-http-options.model';
import { BaseRequestEvent } from '../../_models/core/data/dtos/events/base-request-event.model';

@Injectable({
    providedIn: 'root'
})
export class DeviceService
{
    private readonly requestedType: string = "v1.Identifiers.Device"

    constructor(private apiService: ApiService)
    {

    }

    getDevices(bypassCache: boolean = false): Observable<any[]>
    {
        const options = new ApiHttpOptions();

        options.method = "GET";
        options.requestedType = this.requestedType;
        options.requestEvent = <BaseRequestEvent>{ getAll: true };
        options.bypassCache = bypassCache;

        return this.apiService.runWithOptions(options);
    }

    getDeviceById(id: string, bypassCache: boolean = false): Observable<any>
    {
        const options = new ApiHttpOptions();

        options.method = "GET";
        options.requestedType = this.requestedType;
        options.requestEvent = <BaseRequestEvent>{ id: id };
        options.bypassCache = bypassCache;

        return this.apiService.runWithOptions(options);
    }

    getDeviceByFingerprint(fingerprint: string, bypassCache: boolean = false): Observable<any>
    {
        const options = new ApiHttpOptions();

        options.method = "GET";
        options.requestedType = this.requestedType;
        options.requestEvent = <BaseRequestEvent>{ getAll: true, where: `fingerprint = "${fingerprint}"`, includeInActive: true, includeNonPublic: true };
        options.bypassCache = bypassCache;

        return this.apiService.runWithOptions(options);
    }

    saveDevice(model: any, bypassCache: boolean = true): Observable<any>
    {
        const options = new ApiHttpOptions();

        options.method = "POST";
        options.requestedType = this.requestedType;
        options.requestEvent = <BaseRequestEvent>{ method: RequestEventType.Post, payload: model };
        options.bypassCache = bypassCache;

        return this.apiService.runWithOptions(options);
    }

    deleteDevice(deviceId: string, bypassCache: boolean = true): Observable<any>
    {
        const options = new ApiHttpOptions();

        options.method = "DELETE";
        options.requestedType = this.requestedType;
        options.requestEvent = <BaseRequestEvent>{ method: RequestEventType.Delete, id: deviceId };
        options.bypassCache = bypassCache;

        return this.apiService.runWithOptions(options);
    }
}
