import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'groupBy'
})
export class GroupByPipe implements PipeTransform
{
    transform(value: any[], key: string): any
    {
        if (!value)
            return value;

        const groupedObj = value.reduce((prev, cur) =>
        {
            if (!cur)
                return prev;

            const prop = key.split('.').reduce((o, i) => o[i], cur); // Handle nested properties

            if (!prev[prop])
                prev[prop] = [cur];
            else
                prev[prop].push(cur);

            return prev;
        }, {});

        return Object.keys(groupedObj).map(key => ({ key, items: groupedObj[key] }));
    }
}
