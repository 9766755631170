import { ImapActions, ImapActionTypes } from "./../actions/imap.actions";
import { initialImapState, ImapState } from "../states/imap.states";
import { Utility } from '../../../../../_shared/utils';

export function imapReducer(state = initialImapState, action: ImapActions): ImapState
{
    switch (action.type)
    {
        case ImapActionTypes.SelectImap:
            return {
                ...state,
                selectedImapId: action.payload.imapId, // Or selectedImap: action.payload.imap
            };
        case ImapActionTypes.DeselectImap:
            return {
                ...state,
                selectedImapId: null, // Or selectedImap: null
            };

        case ImapActionTypes.LoadImaps:
        case ImapActionTypes.LoadImapById: {
            return {
                ...state,
                loading: true,
                error: null
            };
        }
        case ImapActionTypes.LoadImapsSuccess: {
            return {
                ...state,
                imaps: action.payload.imaps,
                loading: false
            };
        }
        case ImapActionTypes.LoadImapByIdSuccess: {
            return {
                ...state,
                selectedImap: action.payload.imap,
                imap: Utility.detectAndConvert(action.payload.imap),
                loading: false
            };
        }
        case ImapActionTypes.LoadImapsFailure:
        case ImapActionTypes.LoadImapByIdFailure: {
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        }

        case ImapActionTypes.LoadImap:
        case ImapActionTypes.SaveImap:
        case ImapActionTypes.DeleteImap: {
            return {
                ...state,
                loading: true,
                error: null
            };
        }
        case ImapActionTypes.LoadImapSuccess:
        case ImapActionTypes.SaveImapSuccess: {
            return {
                ...state,
                imap: action.payload.imap,
                loading: false
            };
        }
        case ImapActionTypes.LoadImapFailure:
        case ImapActionTypes.SaveImapFailure:
        case ImapActionTypes.DeleteImapFailure: {
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        }
        case ImapActionTypes.DeleteImapSuccess: {
            return {
                ...state,
                imap: null,
                loading: false
            };
        }
        default:
            return state;
    }
}