import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError, tap } from 'rxjs/operators';
import { Menu } from 'src/app/_models/data-store/menu';
import { MenuService } from '../../../_services/data-store/menu.service';
import { MenuActionTypes, SaveMenu, SaveMenuSuccess, SaveMenuFailure, DeleteMenu, DeleteMenuSuccess, DeleteMenuFailure, LoadMenusFailure, LoadMenuById, LoadMenuByIdFailure, LoadMenuByIdSuccess, LoadMenus, LoadMenusSuccess, ScrollToTopAction, LoadMenu, LoadMenuFailure, LoadMenuSuccess } from './../actions/menu.actions';

@Injectable()
export class MenuEffects
{
    constructor(private actions$: Actions, private backendService: MenuService, private router: Router) 
    {

    }

    loadMenus$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadMenus>(MenuActionTypes.LoadMenus),
            mergeMap(action =>
                this.backendService.getMenus(action.payload.applicationId, action.payload.clientId, action.payload.deviceId, action.payload.bypassCache).pipe(
                    map(menus => new LoadMenusSuccess({ menus, bypassCache: action.payload.bypassCache })),
                    catchError(error => of(new LoadMenusFailure({ error })))
                )
            )
        )
    );

    loadMenuById$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadMenuById>(MenuActionTypes.LoadMenuById),
            mergeMap(action =>
                this.backendService.getMenuById(action.payload.menuId, action.payload.bypassCache).pipe(
                    map(menu => new LoadMenuByIdSuccess({ menu, bypassCache: action.payload.bypassCache })),
                    catchError(error => of(new LoadMenuByIdFailure({ error })))
                )
            )
        )
    );

    loadMenu$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadMenu>(MenuActionTypes.LoadMenu),
            mergeMap(action =>
                this.backendService.getMenu(action.payload.applicationId, action.payload.clientId, action.payload.deviceId, action.payload.sectionId, action.payload.bypassCache, action.payload.enableLoading).pipe(
                    map(menu =>
                    {
                        // Sort the menu items by Index
                        const sortedMenu: Menu = {
                            ...menu[0],
                            Items: menu[0].Items?.sort((a, b) => a.Index - b.Index)
                        };
                        return new LoadMenuSuccess({ menu: sortedMenu, bypassCache: action.payload.bypassCache });
                    }),
                    catchError(error => of(new LoadMenuFailure({ error })))
                )
            )
        )
    );

    saveMenu$ = createEffect(() =>
        this.actions$.pipe(
            ofType<SaveMenu>(MenuActionTypes.SaveMenu),
            mergeMap(action =>
                this.backendService.saveMenu(action.payload.menu, action.payload.bypassCache).pipe(
                    map(menu => new SaveMenuSuccess({ menu, applicationId: action.payload.applicationId, clientId: action.payload.clientId, deviceId: action.payload.deviceId, bypassCache: action.payload.bypassCache })),
                    catchError(error => of(new SaveMenuFailure({ error })))
                )
            )
        )
    );

    // Effect to handle SaveMenuSuccess and dispatch LoadMenus
    saveDataSuccessLoadMenus$ = createEffect(() =>
        this.actions$.pipe(
            ofType<SaveMenuSuccess>(MenuActionTypes.SaveMenuSuccess),
            map(action => new LoadMenus({ applicationId: action.payload.applicationId, clientId: action.payload.clientId, deviceId: action.payload.deviceId, bypassCache: action.payload.bypassCache }))
        )
    );

    saveDataSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType<SaveMenuSuccess>(MenuActionTypes.SaveMenuSuccess),
            map(action => new LoadMenus({ applicationId: action.payload.applicationId, clientId: action.payload.clientId, deviceId: action.payload.deviceId, bypassCache: true }))
        )
    );

    deleteMenu$ = createEffect(() =>
        this.actions$.pipe(
            ofType<DeleteMenu>(MenuActionTypes.DeleteMenu),
            mergeMap(action =>
                this.backendService.deleteMenu(action.payload.menuId, action.payload.bypassCache).pipe(
                    map(() => new DeleteMenuSuccess()),
                    catchError(error => of(new DeleteMenuFailure({ error })))
                )
            )
        )
    );

    // Ensure that all your effect functions return Observables of Actions

    scrollToTop$ = createEffect(() =>
        this.actions$.pipe(
            ofType<SaveMenuSuccess>(MenuActionTypes.SaveMenuSuccess),
            map(() => new ScrollToTopAction())
        )
    );
}