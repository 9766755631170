import { Injectable, ComponentFactoryResolver, ViewContainerRef, ComponentRef } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ComponentService
{
    constructor(private cfr: ComponentFactoryResolver) 
    {

    }

    async loadComponent(vcr: ViewContainerRef, componentRef: any, data: any)
    {
        vcr.clear();

        const component = vcr.createComponent(this.cfr.resolveComponentFactory(componentRef));

        if (component != null && component.instance != null)
            (<any>component.instance).Params = data;

        return component;
    }
}