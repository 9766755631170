import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NavController, Platform } from '@ionic/angular';
import { ActionPerformed, PushNotificationSchema, PushNotifications, Token } from '@capacitor/push-notifications';
import { Device } from './_models/device';
import { DeviceInfoService } from './_services/identifiers/device-info.service';
import { PageStateService } from './_services/page-state.service';

import { select, Store } from '@ngrx/store';
import { filter, interval, Observable, Subject, switchMap, take, takeUntil } from 'rxjs';
import { selectMenu } from './_ngrx-store/menu/selectors/menu.selectors';
import { MenuController } from '@ionic/angular';
import { Menu } from './_models/data-store/menu';
import { AuthenticationService } from './_services/iam/authentication.service';
import { MenuService } from './_services/menu.service';
import { SignalRService } from './_services/signalr.service';
import { LoadMessages } from './_ngrx-store/extended/messages/actions/message.actions';
import { selectMessages } from './_ngrx-store/extended/messages/selectors/message.selectors';
import { AutoUnsubscribe } from './_shared/page/auto-unsubcribe';
import { LoginService } from './_services/iam/login.service';


@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent extends AutoUnsubscribe implements OnInit
{
    private applicationId: string = localStorage.getItem(AuthenticationService.applicationIdKey)!;
    private clientId: string = localStorage.getItem(AuthenticationService.clientIdKey)!;
    private deviceId: string = localStorage.getItem(AuthenticationService.deviceIdKey)!;

    public appPages$!: Observable<Menu>;
    public deviceInfo: any;
    public device: Device | undefined;
    private hasDeviceInfoLoaded: boolean = false;
    private isMenuLoaded: boolean = false;
    public isLoggedIn: boolean = false;

    constructor(private store: Store, private platform: Platform, private menu: MenuController, private router: Router, private deviceInfoService: DeviceInfoService, private pageStateService: PageStateService, private authenticationService: AuthenticationService, private menuService: MenuService, private signalRService: SignalRService, private navController: NavController, private loginService: LoginService)
    {
        super();

        this.initializeApp();
    }

    async ngOnInit()
    {
        this.startMenuRefresh();
        this.authenticationService.isLoggedIn$.subscribe(async isLoggedIn =>
        {
            this.isLoggedIn = isLoggedIn;

            if (!this.isLoggedIn)
            {
                this.isMenuLoaded = false;

                this.menuService.clearMenu();

                return;
            }

            if (!this.isMenuLoaded)
            {
                this.renderMenu();

                this.appPages$ = this.menuService.appPages$;
                this.isMenuLoaded = true;
            }

            await this.signalRService.initialize();
        });


        this.router.events.subscribe(async event =>
        {
            if (event instanceof NavigationEnd)
            {
                const currentPage = this.router.url.split('?')[0];  // Get the URL without query parameters

                this.pageStateService.setCurrentPage(currentPage);

                if (!this.hasDeviceInfoLoaded)
                {
                    try
                    {
                        this.deviceInfo = await this.deviceInfoService.getInfo();

                        this.deviceInfoService.device$?.subscribe((device: any) =>
                        {
                            if (!device)
                                return;

                            if (Array.isArray(device) && (<any[]>device).length > 0)
                            {
                                const fingerprint = this.deviceInfoService.fingerprint?.toString();

                                if (fingerprint)
                                    device = device.find(o => o.Fingerprint == fingerprint);
                            }

                            if (!device.Id)
                                return;

                            if (!device.IsActive || !device.IsPublic || device.IsDeleted)
                                alert("deactivated");
                            else
                            {
                                this.device = device;
                                this.hasDeviceInfoLoaded = true;
                            }
                        });
                    }
                    catch (error)
                    {

                    }
                }
            }
        });
    }

    renderMenu()
    {
        this.menuService.loadMenu(this.applicationId, this.clientId, this.deviceId);
    }

    private menuRefreshInterval = 5 * 60 * 1000; // 2 minute
    private stopRefresh$ = new Subject<void>();

    refreshMenu(): Observable<any>
    {
        return new Observable(observer =>
        {
            observer.next(this.store.pipe(select(selectMenu)));
            observer.complete();
        });
    }

    startMenuRefresh()
    {
        interval(this.menuRefreshInterval)
            .pipe
            (
                switchMap(() => this.refreshMenu()),
                takeUntil(this.stopRefresh$)
            )
            .subscribe
            (
                {
                    next: () =>
                    {
                        this.renderMenu();
                    },
                    error: (error: any) =>
                    {
                        console.error(error);
                    }
                }
            );
    }

    stopTokenRefresh()
    {
        this.stopRefresh$.next();
    }

    closeMenu()
    {
        this.menu.close(); // Function to close the menu
    }

    do(action?: string)
    {
        if (action)
        {
            eval(action);

            this.menu.close();
        }
    }



    initializeApp()
    {
        this.platform.ready().then(() =>
        {
            if (this.platform.is('hybrid'))
                this.initializePushNotifications();
        });
    }

    initializePushNotifications()
    {
        PushNotifications.removeAllListeners();

        // Request permission to use push notifications
        // iOS will prompt user and return if they granted permission or not
        // Android will just grant without prompting
        PushNotifications.requestPermissions().then(result =>
        {
            if (result.receive === 'granted')
                // Register with Apple / Google to receive push via APNS/FCM
                PushNotifications.register();
        });

        // On success, we should be able to receive notifications
        PushNotifications.addListener('registration', (token: Token) =>
        {
            // alert('Push registration success, token: ' + token.value);
            localStorage.setItem("pushNotificationToken", token.value);
        });

        // Some issue with our setup and push will not work
        PushNotifications.addListener('registrationError', (error: any) =>
        {
            // alert('Error on registration: ' + JSON.stringify(error));
        });

        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) =>
        {
            alert('Push received: ' + JSON.stringify(notification));
        });

        // Method called when tapping on a notification
        PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) =>
        {
            this.redirect(notification);
        });
    }

    redirect(notification: any)
    {
        const pushNotificationToken: string | null = localStorage.getItem("pushNotificationToken");

        if (pushNotificationToken)
        {
            const fingerprint = this.deviceInfoService.fingerprint.toString();
            const deviceInfoJson = this.deviceInfoService.deviceInfoJson;
            const observer = {
                next: (response: any) =>
                {
                    if (response && response.AuthToken?.access_token)
                    {
                        this.authenticationService.setApplicationId(response.ApplicationId);
                        this.authenticationService.setClientId(response.ClientId);
                        this.authenticationService.setDeviceId(response.DeviceId);
                        this.authenticationService.setAuthToken(response.AuthToken);
                        this.authenticationService.setEmail(response.Email);
                        this.authenticationService.success();

                        this.menuService.loadMenu(response.ApplicationId, response.ClientId, response.DeviceId);
                        this.signalRService.initialize();

                        if (notification?.notification?.data?.UId)
                            this.navController.navigateForward("/view/read/" + notification.notification.data.UId);
                    }
                },
                error: (e: any) =>
                {
                    console.error(e);
                }
            };

            this.loginService.loginByPushNotificationToken(pushNotificationToken, 'en-CA', fingerprint, deviceInfoJson).subscribe(observer);
        }
    }
}
