import { DeviceActions, DeviceActionTypes } from "./../actions/device.actions";
import { initialDeviceState, DeviceState } from "../states/device.states";
import { Utility } from '../../../_shared/utils';

export function deviceReducer(state = initialDeviceState, action: DeviceActions): DeviceState
{
    switch (action.type)
    {
        case DeviceActionTypes.SelectDevice:
            return {
                ...state,
                selectedDeviceId: action.payload.deviceId, // Or selectedDevice: action.payload.device
            };
        case DeviceActionTypes.DeselectDevice:
            return {
                ...state,
                selectedDeviceId: null, // Or selectedDevice: null
            };

        case DeviceActionTypes.LoadDevices:
        case DeviceActionTypes.LoadDeviceById:
        case DeviceActionTypes.LoadDeviceByFingerprint: {
            return {
                ...state,
                loading: true,
                error: null
            };
        }
        case DeviceActionTypes.LoadDevicesSuccess: {
            return {
                ...state,
                devices: action.payload.devices,
                loading: false
            };
        }
        case DeviceActionTypes.LoadDeviceByIdSuccess:
        case DeviceActionTypes.LoadDeviceByFingerprintSuccess: {
            return {
                ...state,
                selectedDevice: action.payload.device,
                device: Utility.detectAndConvert(action.payload.device),
                loading: false
            };
        }
        case DeviceActionTypes.LoadDevicesFailure:
        case DeviceActionTypes.LoadDeviceByIdFailure:
        case DeviceActionTypes.LoadDeviceByFingerprintFailure: {
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        }

        case DeviceActionTypes.LoadDevice:
        case DeviceActionTypes.SaveDevice:
        case DeviceActionTypes.DeleteDevice: {
            return {
                ...state,
                loading: true,
                error: null
            };
        }
        case DeviceActionTypes.LoadDeviceSuccess:
        case DeviceActionTypes.SaveDeviceSuccess: {
            return {
                ...state,
                device: action.payload.device,
                loading: false
            };
        }
        case DeviceActionTypes.LoadDeviceFailure:
        case DeviceActionTypes.SaveDeviceFailure:
        case DeviceActionTypes.DeleteDeviceFailure: {
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        }
        case DeviceActionTypes.DeleteDeviceSuccess: {
            return {
                ...state,
                device: null,
                loading: false
            };
        }
        default:
            return state;
    }
}