import { createSelector, createFeatureSelector } from '@ngrx/store';
import { Device } from 'src/app/_models/device';
import { DeviceState } from '../states/device.states';

export const selectDevicesState = createFeatureSelector<DeviceState>('device');
export const selectDeviceState = createFeatureSelector<DeviceState>('device');

export const selectSelectedDevice = createSelector(
    selectDeviceState,
    (state: DeviceState) =>
    {
        if (!state.selectedDeviceId)
        {
            return null;
        }
        const record = state.devices?.find(device => device.DeviceId === state.selectedDeviceId);

        return record;
    }
);

export const selectDevices = createSelector(
    selectDevicesState,
    state => state.devices || [] as Device[]
);

export const selectDevice = createSelector(
    selectDeviceState,
    state => state.device || {} as Device
);

export const selectDeviceLoading = createSelector(
    selectDeviceState,
    state => state.loading
);

export const selectDeviceError = createSelector(
    selectDeviceState,
    state => state.error
);
