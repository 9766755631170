import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError, tap } from 'rxjs/operators';
import { ImapService } from 'src/app/_services/data-store/extended/configurations/imap/imap.service';
import { ImapActionTypes, SaveImap, SaveImapSuccess, SaveImapFailure, DeleteImap, DeleteImapSuccess, DeleteImapFailure, LoadImapsFailure, LoadImapById, LoadImapByIdFailure, LoadImapByIdSuccess, LoadImaps, LoadImapsSuccess, ScrollToTopAction } from './../actions/imap.actions';

@Injectable()
export class ImapEffects
{
    constructor(private actions$: Actions, private backendService: ImapService, private router: Router) 
    {

    }

    loadImaps$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadImaps>(ImapActionTypes.LoadImaps),
            mergeMap(action =>
                this.backendService.getImaps(action.payload.applicationId, action.payload.clientId, action.payload.deviceId, action.payload.bypassCache).pipe(
                    map(imaps => new LoadImapsSuccess({ imaps, bypassCache: action.payload.bypassCache })),
                    catchError(error => of(new LoadImapsFailure({ error })))
                )
            )
        )
    );

    loadImapById$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadImapById>(ImapActionTypes.LoadImapById),
            mergeMap(action =>
                this.backendService.getImapById(action.payload.imapId, action.payload.bypassCache).pipe(
                    map(imap => new LoadImapByIdSuccess({ imap, bypassCache: action.payload.bypassCache })),
                    catchError(error => of(new LoadImapByIdFailure({ error })))
                )
            )
        )
    );

    saveImap$ = createEffect(() =>
        this.actions$.pipe(
            ofType<SaveImap>(ImapActionTypes.SaveImap),
            mergeMap(action =>
                this.backendService.saveImap(action.payload.imap, action.payload.bypassCache).pipe(
                    map(imap => new SaveImapSuccess({ imap, applicationId: action.payload.applicationId, clientId: action.payload.clientId, deviceId: action.payload.deviceId, bypassCache: action.payload.bypassCache })),
                    catchError(error => of(new SaveImapFailure({ error })))
                )
            )
        )
    );

    // Effect to handle SaveImapSuccess and dispatch LoadImaps
    saveDataSuccessLoadImaps$ = createEffect(() =>
        this.actions$.pipe(
            ofType<SaveImapSuccess>(ImapActionTypes.SaveImapSuccess),
            map(action => new LoadImaps({ applicationId: action.payload.applicationId, clientId: action.payload.clientId, deviceId: action.payload.deviceId, bypassCache: action.payload.bypassCache }))
        )
    );

    saveDataSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType<SaveImapSuccess>(ImapActionTypes.SaveImapSuccess),
            map(action => new LoadImaps({ applicationId: action.payload.applicationId, clientId: action.payload.clientId, deviceId: action.payload.deviceId, bypassCache: true }))
        )
    );

    deleteImap$ = createEffect(() =>
        this.actions$.pipe(
            ofType<DeleteImap>(ImapActionTypes.DeleteImap),
            mergeMap(action =>
                this.backendService.deleteImap(action.payload.imapId, action.payload.bypassCache).pipe(
                    map(() => new DeleteImapSuccess()),
                    catchError(error => of(new DeleteImapFailure({ error })))
                )
            )
        )
    );

    // Ensure that all your effect functions return Observables of Actions

    scrollToTop$ = createEffect(() =>
        this.actions$.pipe(
            ofType<SaveImapSuccess>(ImapActionTypes.SaveImapSuccess),
            map(() => new ScrollToTopAction())
        )
    );
}