import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AlertController } from '@ionic/angular';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor
{
    constructor(private alertController: AlertController) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) =>
            {
                let errorMessage = '';

                if (error?.error instanceof ErrorEvent)
                {
                    // Client-side error
                    errorMessage = `An error occurred: ${error.error.message}`;
                }
                else if (error?.status && error.error.Errors)
                {
                    // Server-side error
                    errorMessage = `Backend returned code ${error.status}, body was: ${JSON.stringify(error.error.Errors)}`;
                }

                if (errorMessage !== '')
                {
                    // Display Ionic alert
                    const alert = this.alertController.create({
                        header: 'Error',
                        message: errorMessage,
                        buttons: ['OK']
                    }).then(alert => alert.present());

                    return throwError(() => new Error(errorMessage));
                }

                return EMPTY;
            })
        );
    }
}
