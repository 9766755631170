import { Device } from "src/app/_models/device";

export interface DeviceState
{
    devices: Device[] | null;
    selectedDevice: Device | null;
    selectedDeviceId: string | null;
    device: Device | null;
    loading: boolean;
    error: any;
}

export const initialDeviceState: DeviceState = {
    devices: null,
    selectedDevice: null,
    selectedDeviceId: null,
    device: null,
    loading: false,
    error: null
};