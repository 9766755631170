export class AlertOptions
{
    header?: string;
    message?: string;
    okButtonText?: string;
    cancelButtonText?: string;
    successFunc?: any;
    cancelFunc?: any;
    cssClass?: string;

    constructor()
    {
        this.header = "";
        this.message = "";
        this.okButtonText = "Ok";
        this.cssClass = "modal-alert-sm";
    }
}