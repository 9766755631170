// device.actions.ts

import { Action } from "@ngrx/store";
import { Device } from "src/app/_models/device";

export enum DeviceActionTypes
{
    SelectDevice = '[Device] Select Device',
    DeselectDevice = '[Device] Deselect Device',
    LoadDevice = '[Device] Load Device',
    LoadDeviceSuccess = '[Device] Load Device Success',
    LoadDeviceFailure = '[Device] Load Device Failure',
    SaveDevice = '[Device] Create Device',
    SaveDeviceSuccess = '[Device] Create Device Success',
    SaveDeviceFailure = '[Device] Create Device Failure',
    DeleteDevice = '[Device] Delete Device',
    DeleteDeviceSuccess = '[Device] Delete Device Success',
    DeleteDeviceFailure = '[Device] Delete Device Failure',

    LoadDevices = '[Device] Load Devices',
    LoadDevicesSuccess = '[Device] Load Devices Success',
    LoadDevicesFailure = '[Device] Load Devices Failure',
    LoadDeviceById = '[Device] Load Device By Id',
    LoadDeviceByIdSuccess = '[Device] Load Device By Id Success',
    LoadDeviceByIdFailure = '[Device] Load Device By Id Failure',
    LoadDeviceByFingerprint = '[Device] Load Device By Fingerprint',
    LoadDeviceByFingerprintSuccess = '[Device] Load Device By Fingerprint Success',
    LoadDeviceByFingerprintFailure = '[Device] Load Device By Fingerprint Failure',

    ScrollToTop = '[Device] Scroll To Top',
}

export class SelectDevice implements Action
{
    readonly type = DeviceActionTypes.SelectDevice;
    constructor(public payload: { deviceId: string }) { } // Or payload: { device: Device }
}

export class DeselectDevice implements Action
{
    readonly type = DeviceActionTypes.DeselectDevice;
}

export class LoadDevices implements Action
{
    readonly type = DeviceActionTypes.LoadDevices;
    constructor(public payload: { bypassCache: boolean }) { }
}

export class LoadDevicesSuccess implements Action
{
    readonly type = DeviceActionTypes.LoadDevicesSuccess;
    constructor(public payload: { devices: Device[], bypassCache: boolean }) { }
}

export class LoadDevicesFailure implements Action
{
    readonly type = DeviceActionTypes.LoadDevicesFailure;
    constructor(public payload: { error: any }) { }
}

export class LoadDeviceById implements Action
{
    readonly type = DeviceActionTypes.LoadDeviceById;
    constructor(public payload: { deviceId: string, bypassCache: boolean }) { }
}

export class LoadDeviceByIdSuccess implements Action
{
    readonly type = DeviceActionTypes.LoadDeviceByIdSuccess;
    constructor(public payload: { device: Device, bypassCache: boolean }) { }
}

export class LoadDeviceByIdFailure implements Action
{
    readonly type = DeviceActionTypes.LoadDeviceByIdFailure;
    constructor(public payload: { error: any }) { }
}

export class LoadDeviceByFingerprint implements Action
{
    readonly type = DeviceActionTypes.LoadDeviceByFingerprint;
    constructor(public payload: { fingerprint: string, bypassCache: boolean }) { }
}

export class LoadDeviceByFingerprintSuccess implements Action
{
    readonly type = DeviceActionTypes.LoadDeviceByFingerprintSuccess;
    constructor(public payload: { device: Device, bypassCache: boolean }) { }
}

export class LoadDeviceByFingerprintFailure implements Action
{
    readonly type = DeviceActionTypes.LoadDeviceByFingerprintFailure;
    constructor(public payload: { error: any }) { }
}

export class LoadDevice implements Action
{
    readonly type = DeviceActionTypes.LoadDevice;
}

export class LoadDeviceSuccess implements Action
{
    readonly type = DeviceActionTypes.LoadDeviceSuccess;
    constructor(public payload: { device: Device, bypassCache: boolean }) { }
}

export class LoadDeviceFailure implements Action
{
    readonly type = DeviceActionTypes.LoadDeviceFailure;
    constructor(public payload: { error: any }) { }
}

export class SaveDevice implements Action
{
    readonly type = DeviceActionTypes.SaveDevice;
    constructor(public payload: { device: Device, bypassCache: boolean }) { }
}

export class SaveDeviceSuccess implements Action
{
    readonly type = DeviceActionTypes.SaveDeviceSuccess;
    constructor(public payload: { device: Device, bypassCache: boolean }) { }
}

export class SaveDeviceFailure implements Action
{
    readonly type = DeviceActionTypes.SaveDeviceFailure;
    constructor(public payload: { error: any }) { }
}

export class DeleteDevice implements Action
{
    readonly type = DeviceActionTypes.DeleteDevice;
    constructor(public payload: { deviceId: string, bypassCache: boolean }) { }
}

export class DeleteDeviceSuccess implements Action
{
    readonly type = DeviceActionTypes.DeleteDeviceSuccess;
}

export class DeleteDeviceFailure implements Action
{
    readonly type = DeviceActionTypes.DeleteDeviceFailure;
    constructor(public payload: { error: any }) { }
}

export class ScrollToTopAction implements Action
{
    readonly type = DeviceActionTypes.ScrollToTop;
}

export type DeviceActions =
    | LoadDevice
    | LoadDeviceSuccess
    | LoadDeviceFailure
    | SaveDevice
    | SaveDeviceSuccess
    | SaveDeviceFailure
    | DeleteDevice
    | DeleteDeviceSuccess
    | DeleteDeviceFailure
    | LoadDevices
    | LoadDeviceById
    | LoadDevicesSuccess
    | LoadDeviceByIdSuccess
    | LoadDevicesFailure
    | LoadDeviceByIdFailure
    | LoadDeviceByFingerprint
    | LoadDeviceByFingerprintSuccess
    | LoadDeviceByFingerprintFailure
    | SelectDevice
    | DeselectDevice
    ;
