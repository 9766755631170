import { Pop } from "src/app/_models/data-store/extended/configurations/pop";

export interface PopState
{
    pops: Pop[] | null;
    selectedPop: Pop | null;
    selectedPopId: string | null;
    pop: Pop | null;
    loading: boolean;
    error: any;
}

export const initialPopState: PopState = {
    pops: null,
    selectedPop: null,
    selectedPopId: null,
    pop: null,
    loading: false,
    error: null
};