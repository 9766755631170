// menu.actions.ts

import { Action } from "@ngrx/store";
import { Menu } from "src/app/_models/data-store/menu";

export enum MenuActionTypes
{
    SelectMenu = '[Menu] Select Menu',
    DeselectMenu = '[Menu] Deselect Menu',
    LoadMenu = '[Menu] Load Menu',
    LoadMenuSuccess = '[Menu] Load Menu Success',
    LoadMenuFailure = '[Menu] Load Menu Failure',
    SaveMenu = '[Menu] Create Menu',
    SaveMenuSuccess = '[Menu] Create Menu Success',
    SaveMenuFailure = '[Menu] Create Menu Failure',
    DeleteMenu = '[Menu] Delete Menu',
    DeleteMenuSuccess = '[Menu] Delete Menu Success',
    DeleteMenuFailure = '[Menu] Delete Menu Failure',

    LoadMenus = '[Menu] Load Menus',
    LoadMenusSuccess = '[Menu] Load Menus Success',
    LoadMenusFailure = '[Menu] Load Menus Failure',
    LoadMenuById = '[Menu] Load Menu By Id',
    LoadMenuByIdSuccess = '[Menu] Load Menu By Id Success',
    LoadMenuByIdFailure = '[Menu] Load Menu By Id Failure',

    ClearMenu = '[Menu] Clear Menu',

    ScrollToTop = '[Menu] Scroll To Top',
}

export class SelectMenu implements Action
{
    readonly type = MenuActionTypes.SelectMenu;
    constructor(public payload: { menuId: string }) { } // Or payload: { menu: Menu }
}

export class DeselectMenu implements Action
{
    readonly type = MenuActionTypes.DeselectMenu;
}

export class LoadMenus implements Action
{
    readonly type = MenuActionTypes.LoadMenus;
    constructor(public payload: { applicationId: string, clientId: string, deviceId: string, bypassCache: boolean }) { }
}

export class LoadMenusSuccess implements Action
{
    readonly type = MenuActionTypes.LoadMenusSuccess;
    constructor(public payload: { menus: Menu[], bypassCache: boolean }) { }
}

export class LoadMenusFailure implements Action
{
    readonly type = MenuActionTypes.LoadMenusFailure;
    constructor(public payload: { error: any }) { }
}

export class LoadMenuById implements Action
{
    readonly type = MenuActionTypes.LoadMenuById;
    constructor(public payload: { menuId: string, bypassCache: boolean }) { }
}

export class LoadMenuByIdSuccess implements Action
{
    readonly type = MenuActionTypes.LoadMenuByIdSuccess;
    constructor(public payload: { menu: Menu, bypassCache: boolean }) { }
}

export class LoadMenuByIdFailure implements Action
{
    readonly type = MenuActionTypes.LoadMenuByIdFailure;
    constructor(public payload: { error: any }) { }
}

export class LoadMenu implements Action
{
    readonly type = MenuActionTypes.LoadMenu;
    constructor(public payload: { applicationId: string | null, clientId: string | null, deviceId: string | null, sectionId: number, bypassCache: boolean, enableLoading: boolean }) { }
}

export class LoadMenuSuccess implements Action
{
    readonly type = MenuActionTypes.LoadMenuSuccess;
    constructor(public payload: { menu: Menu, bypassCache: boolean }) { }
}

export class LoadMenuFailure implements Action
{
    readonly type = MenuActionTypes.LoadMenuFailure;
    constructor(public payload: { error: any }) { }
}

export class SaveMenu implements Action
{
    readonly type = MenuActionTypes.SaveMenu;
    constructor(public payload: { menu: Menu, applicationId: string, clientId: string, deviceId: string, bypassCache: boolean }) { }
}

export class SaveMenuSuccess implements Action
{
    readonly type = MenuActionTypes.SaveMenuSuccess;
    constructor(public payload: { menu: Menu, applicationId: string, clientId: string, deviceId: string, bypassCache: boolean }) { }
}

export class SaveMenuFailure implements Action
{
    readonly type = MenuActionTypes.SaveMenuFailure;
    constructor(public payload: { error: any }) { }
}

export class DeleteMenu implements Action
{
    readonly type = MenuActionTypes.DeleteMenu;
    constructor(public payload: { menuId: string, bypassCache: boolean }) { }
}

export class DeleteMenuSuccess implements Action
{
    readonly type = MenuActionTypes.DeleteMenuSuccess;
}

export class DeleteMenuFailure implements Action
{
    readonly type = MenuActionTypes.DeleteMenuFailure;
    constructor(public payload: { error: any }) { }
}

export class ScrollToTopAction implements Action
{
    readonly type = MenuActionTypes.ScrollToTop;
}

export class ClearMenu implements Action
{
    readonly type = MenuActionTypes.ClearMenu;
    constructor() { }
}

export type MenuActions =
    | LoadMenu
    | LoadMenuSuccess
    | LoadMenuFailure
    | SaveMenu
    | SaveMenuSuccess
    | SaveMenuFailure
    | DeleteMenu
    | DeleteMenuSuccess
    | DeleteMenuFailure
    | LoadMenus
    | LoadMenuById
    | LoadMenusSuccess
    | LoadMenuByIdSuccess
    | LoadMenusFailure
    | LoadMenuByIdFailure
    | SelectMenu
    | DeselectMenu
    | ClearMenu
    ;
