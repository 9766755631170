// message.actions.ts

import { Action } from "@ngrx/store";
import { Message } from "src/app/_models/data-store/extended/messages/message";

export enum MessageActionTypes
{
    SelectMessage = '[Message] Select Message',
    DeselectMessage = '[Message] Deselect Message',
    LoadMessage = '[Message] Load Message',
    LoadMessageSuccess = '[Message] Load Message Success',
    LoadMessageFailure = '[Message] Load Message Failure',
    SaveMessage = '[Message] Create Message',
    SaveMessageSuccess = '[Message] Create Message Success',
    SaveMessageFailure = '[Message] Create Message Failure',
    DeleteMessage = '[Message] Delete Message',
    DeleteMessageSuccess = '[Message] Delete Message Success',
    DeleteMessageFailure = '[Message] Delete Message Failure',

    LoadMessages = '[Message] Load Messages',
    LoadMessagesSuccess = '[Message] Load Messages Success',
    LoadMessagesFailure = '[Message] Load Messages Failure',
    LoadMessageById = '[Message] Load Message By Id',
    LoadMessageByIdSuccess = '[Message] Load Message By Id Success',
    LoadMessageByIdFailure = '[Message] Load Message By Id Failure',
    LoadMessageByUId = '[Message] Load Message By UId',
    LoadMessageByUIdSuccess = '[Message] Load Message By UId Success',
    LoadMessageByUIdFailure = '[Message] Load Message By UId Failure',

    AddMessageFromSignalR = '[Message] Add Message From SignalR',

    UpdateMessage = '[Message] Update Message',
    UpdateMessageSuccess = '[Message] Update Message Success',
    UpdateMessageFailure = '[Message] Update Message Failure',

    ScrollToTop = '[Message] Scroll To Top',
}

export class SelectMessage implements Action
{
    readonly type = MessageActionTypes.SelectMessage;
    constructor(public payload: { messageId: string }) { } // Or payload: { message: Message }
}

export class DeselectMessage implements Action
{
    readonly type = MessageActionTypes.DeselectMessage;
}

export class LoadMessages implements Action
{
    readonly type = MessageActionTypes.LoadMessages;
    constructor(public payload: { applicationId: string, clientId: string, deviceId: string, bypassCache: boolean, filterDeleted?: boolean }) { }
}

export class LoadMessagesSuccess implements Action
{
    readonly type = MessageActionTypes.LoadMessagesSuccess;
    constructor(public payload: { messages: Message[], bypassCache: boolean }) { }
}

export class LoadMessagesFailure implements Action
{
    readonly type = MessageActionTypes.LoadMessagesFailure;
    constructor(public payload: { error: any }) { }
}

export class LoadMessageById implements Action
{
    readonly type = MessageActionTypes.LoadMessageById;
    constructor(public payload: { messageId: string, bypassCache: boolean }) { }
}

export class LoadMessageByIdSuccess implements Action
{
    readonly type = MessageActionTypes.LoadMessageByIdSuccess;
    constructor(public payload: { message: Message, bypassCache: boolean }) { }
}

export class LoadMessageByIdFailure implements Action
{
    readonly type = MessageActionTypes.LoadMessageByIdFailure;
    constructor(public payload: { error: any }) { }
}

export class LoadMessageByUId implements Action
{
    readonly type = MessageActionTypes.LoadMessageByUId;
    constructor(public payload: { uId: string, bypassCache: boolean }) { }
}

export class LoadMessageByUIdSuccess implements Action
{
    readonly type = MessageActionTypes.LoadMessageByUIdSuccess;
    constructor(public payload: { message: Message, bypassCache: boolean }) { }
}

export class LoadMessageByUIdFailure implements Action
{
    readonly type = MessageActionTypes.LoadMessageByUIdFailure;
    constructor(public payload: { error: any }) { }
}

export class LoadMessage implements Action
{
    readonly type = MessageActionTypes.LoadMessage;
}

export class LoadMessageSuccess implements Action
{
    readonly type = MessageActionTypes.LoadMessageSuccess;
    constructor(public payload: { message: Message, bypassCache: boolean }) { }
}

export class LoadMessageFailure implements Action
{
    readonly type = MessageActionTypes.LoadMessageFailure;
    constructor(public payload: { error: any }) { }
}

export class SaveMessage implements Action
{
    readonly type = MessageActionTypes.SaveMessage;
    constructor(public payload: { message: Message, applicationId: string, clientId: string, deviceId: string, bypassCache: boolean }) { }
}

export class SaveMessageSuccess implements Action
{
    readonly type = MessageActionTypes.SaveMessageSuccess;
    constructor(public payload: { message: Message, applicationId: string, clientId: string, deviceId: string, bypassCache: boolean }) { }
}

export class SaveMessageFailure implements Action
{
    readonly type = MessageActionTypes.SaveMessageFailure;
    constructor(public payload: { error: any }) { }
}

export class DeleteMessage implements Action
{
    readonly type = MessageActionTypes.DeleteMessage;
    constructor(public payload: { messageId: string, bypassCache: boolean }) { }
}

export class DeleteMessageSuccess implements Action
{
    readonly type = MessageActionTypes.DeleteMessageSuccess;
}

export class DeleteMessageFailure implements Action
{
    readonly type = MessageActionTypes.DeleteMessageFailure;
    constructor(public payload: { error: any }) { }
}

export class ScrollToTopAction implements Action
{
    readonly type = MessageActionTypes.ScrollToTop;
}

export class AddMessageFromSignalR implements Action
{
    readonly type = MessageActionTypes.AddMessageFromSignalR;
    constructor(public payload: { message: Message }) { }
}

export class UpdateMessage implements Action
{
    readonly type = MessageActionTypes.UpdateMessage;
    constructor(public payload: { messageId: string, changes: Partial<Message> }) { }
}

export class UpdateMessageSuccess implements Action
{
    readonly type = MessageActionTypes.UpdateMessageSuccess;
    constructor(public payload: { message: Message }) { }
}

export class UpdateMessageFailure implements Action
{
    readonly type = MessageActionTypes.UpdateMessageFailure;
    constructor(public payload: { error: any }) { }
}

export type MessageActions =
    | LoadMessage
    | LoadMessageSuccess
    | LoadMessageFailure
    | SaveMessage
    | SaveMessageSuccess
    | SaveMessageFailure
    | DeleteMessage
    | DeleteMessageSuccess
    | DeleteMessageFailure
    | LoadMessages
    | LoadMessageById
    | LoadMessageByUId 
    | LoadMessagesSuccess
    | LoadMessageByIdSuccess
    | LoadMessageByUIdSuccess 
    | LoadMessagesFailure
    | LoadMessageByIdFailure
    | LoadMessageByUIdFailure
    | SelectMessage
    | DeselectMessage
    | AddMessageFromSignalR
    | UpdateMessage
    | UpdateMessageSuccess
    | UpdateMessageFailure
    ;
