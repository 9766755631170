import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { CustomDatePipe } from '../_pipes/date-pipe.pipe';
import { OrderByPipe } from '../_pipes/order-by.pipe';
import { RoundPipe } from '../_pipes/round.pipe';
import { ModalTemplateComponent } from './modal-template/detail.component';
import { ComponentHostDirective } from '../_directives/component.service';
import { EmailDisplayComponent } from '../_components/email-display/email-display.component';
import { SignalRService } from '../_services/signalr.service';
import { GroupByPipe } from '../_pipes/group-by.pipe';
import { EmailListDatePipe } from '../_pipes/email-list-date.pipe';

@NgModule({
    imports: [
        //core module
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        IonicModule,
    ],
    declarations: [
        //directives
        ComponentHostDirective,
        //pipes
        CustomDatePipe,
        OrderByPipe,
        RoundPipe,
        GroupByPipe,
        EmailListDatePipe,

        ModalTemplateComponent,
        EmailDisplayComponent
    ],
    exports: [
        CustomDatePipe,
        OrderByPipe,
        RoundPipe,
        CurrencyPipe,
        GroupByPipe,
        EmailListDatePipe,

        EmailDisplayComponent
    ],
    providers: [
        CustomDatePipe,
        OrderByPipe,
        RoundPipe,
        CurrencyPipe,
        GroupByPipe,
        EmailListDatePipe,

        SignalRService
    ]
})
export class SharedModule { }
