import { PopActions, PopActionTypes } from "./../actions/pop.actions";
import { initialPopState, PopState } from "../states/pop.states";
import { Utility } from '../../../../../_shared/utils';

export function popReducer(state = initialPopState, action: PopActions): PopState
{
    switch (action.type)
    {
        case PopActionTypes.SelectPop:
            return {
                ...state,
                selectedPopId: action.payload.popId, // Or selectedPop: action.payload.pop
            };
        case PopActionTypes.DeselectPop:
            return {
                ...state,
                selectedPopId: null, // Or selectedPop: null
            };

        case PopActionTypes.LoadPops:
        case PopActionTypes.LoadPopById: {
            return {
                ...state,
                loading: true,
                error: null
            };
        }
        case PopActionTypes.LoadPopsSuccess: {
            return {
                ...state,
                pops: action.payload.pops,
                loading: false
            };
        }
        case PopActionTypes.LoadPopByIdSuccess: {
            return {
                ...state,
                selectedPop: action.payload.pop,
                pop: Utility.detectAndConvert(action.payload.pop),
                loading: false
            };
        }
        case PopActionTypes.LoadPopsFailure:
        case PopActionTypes.LoadPopByIdFailure: {
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        }

        case PopActionTypes.LoadPop:
        case PopActionTypes.SavePop:
        case PopActionTypes.DeletePop: {
            return {
                ...state,
                loading: true,
                error: null
            };
        }
        case PopActionTypes.LoadPopSuccess:
        case PopActionTypes.SavePopSuccess: {
            return {
                ...state,
                pop: action.payload.pop,
                loading: false
            };
        }
        case PopActionTypes.LoadPopFailure:
        case PopActionTypes.SavePopFailure:
        case PopActionTypes.DeletePopFailure: {
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        }
        case PopActionTypes.DeletePopSuccess: {
            return {
                ...state,
                pop: null,
                loading: false
            };
        }
        default:
            return state;
    }
}