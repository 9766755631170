import { Message } from "src/app/_models/data-store/extended/messages/message";

export interface MessageState
{
    messages: Message[] | null;
    selectedMessage: Message | null;
    selectedMessageId: string | null;
    message: Message | null;
    loading: boolean;
    error: any;
}

export const initialMessageState: MessageState = {
    messages: null,
    selectedMessage: null,
    selectedMessageId: null,
    message: null,
    loading: false,
    error: null
};