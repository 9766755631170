import { Injectable } from '@angular/core';
import { initialize } from '@ionic/core';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { Store } from '@ngrx/store';
import { AppConfig } from '../_configuration/configuration';
import { AddMessageFromSignalR } from '../_ngrx-store/extended/messages/actions/message.actions';
import { AuthenticationService } from './iam/authentication.service';

const config = AppConfig.getInstance();

@Injectable({
    providedIn: 'root'
})
export class SignalRService
{
    private hubConnection?: HubConnection;
    private isConntected: boolean = false;
    private initializingPromise: Promise<void> | null = null;
    private maxRetries = 5; // Maximum number of retry attempts
    private retryDelay = 2000; // Delay between retries in milliseconds

    constructor(private store: Store, private authenticationService: AuthenticationService)
    {
        initialize();
    }

    public initialize(): Promise<void>
    {
        if (this.isConntected)
            return Promise.resolve();

        if (this.initializingPromise)
            return this.initializingPromise; // Initialization in progress, return the existing promise

        this.initializingPromise = this.startInitialization()
            .then(() =>
            {
                this.isConntected = true; // Mark as connected
            })
            .finally(() =>
            {
                this.initializingPromise = null; // Reset the initializingPromise
            });

        return this.initializingPromise;
    }

    private async startInitialization(): Promise<void>
    {
        const getToken = async (): Promise<string> =>
        {
            const token = this.authenticationService.getAuthToken();

            if (!token)
            {
                throw new Error("No token found");
            }

            return token;
        };

        this.hubConnection = new HubConnectionBuilder()
            .withUrl(config.signalREndpoint, { accessTokenFactory: () => getToken(), withCredentials: true })
            .build();

        await this.startConnectionWithRetry();

        this.addListeners();
    }

    private async startConnectionWithRetry(retries = this.maxRetries): Promise<void>
    {
        try
        {
            await this.hubConnection?.start();

            this.isConntected = true;
        }
        catch (err)
        {
            // console.error(`Error while starting connection. Retries left: ${retries}. Error: `, err);

            if (retries > 0)
            {
                await this.delay(this.retryDelay);

                return this.startConnectionWithRetry(retries - 1); // Retry connection
            }
            else
            {
                // console.error('Max retry attempts reached. Could not connect to SignalR.');

                // throw err; // Final failure after retries
            }
        }
    }

    private delay(ms: number): Promise<void>
    {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    private addListeners()
    {
        this.hubConnection?.on('v1_DataStore_Mail_Messages_Message_AddMessages', (data) =>
        {
            this.store.dispatch(new AddMessageFromSignalR({ message: data.Message }));
        });
    }

    public sendMessage(methodName: string, data: any, callback?: any)
    {
        this.hubConnection?.invoke(methodName, data).then(callback).catch(err => console.error(err));
    }
}