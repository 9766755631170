import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError, tap } from 'rxjs/operators';
import { MessageService } from 'src/app/_services/data-store/extended/messages/message.service';
import { MessageActionTypes, SaveMessage, SaveMessageSuccess, SaveMessageFailure, DeleteMessage, DeleteMessageSuccess, DeleteMessageFailure, LoadMessagesFailure, LoadMessageById, LoadMessageByIdFailure, LoadMessageByIdSuccess, LoadMessages, LoadMessagesSuccess, ScrollToTopAction, LoadMessageByUId, LoadMessageByUIdFailure, LoadMessageByUIdSuccess } from './../actions/message.actions';

@Injectable()
export class MessageEffects
{
    constructor(private actions$: Actions, private backendService: MessageService)
    {

    }

    loadMessages$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadMessages>(MessageActionTypes.LoadMessages),
            mergeMap(action =>
                this.backendService.getMessages(action.payload.applicationId, action.payload.clientId, action.payload.deviceId, action.payload.bypassCache).pipe(
                    map(messages =>
                        action.payload.filterDeleted === null || action.payload.filterDeleted === undefined || action.payload.filterDeleted
                            ? messages.filter(message => !message.IsDeleted)
                            : messages
                    ),
                    map(filteredMessages => new LoadMessagesSuccess({ messages: filteredMessages, bypassCache: action.payload.bypassCache })),
                    catchError(error => of(new LoadMessagesFailure({ error })))
                )
            )
        )
    );

    loadMessageById$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadMessageById>(MessageActionTypes.LoadMessageById),
            mergeMap(action =>
                this.backendService.getMessageById(action.payload.messageId, action.payload.bypassCache).pipe(
                    map(message => new LoadMessageByIdSuccess({ message, bypassCache: action.payload.bypassCache })),
                    catchError(error => of(new LoadMessageByIdFailure({ error })))
                )
            )
        )
    );

    loadMessageByUId$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadMessageByUId>(MessageActionTypes.LoadMessageByUId),
            mergeMap(action =>
                this.backendService.getMessageByUId(action.payload.uId, action.payload.bypassCache).pipe(
                    map(message => new LoadMessageByUIdSuccess({ message, bypassCache: action.payload.bypassCache })),
                    catchError(error => of(new LoadMessageByUIdFailure({ error })))
                )
            )
        )
    );

    saveMessage$ = createEffect(() =>
        this.actions$.pipe(
            ofType<SaveMessage>(MessageActionTypes.SaveMessage),
            mergeMap(action =>
                this.backendService.saveMessage(action.payload.message, action.payload.bypassCache).pipe(
                    map(message => new SaveMessageSuccess({ message, applicationId: action.payload.applicationId, clientId: action.payload.clientId, deviceId: action.payload.deviceId, bypassCache: action.payload.bypassCache })),
                    catchError(error => of(new SaveMessageFailure({ error })))
                )
            )
        )
    );

    // Effect to handle SaveMessageSuccess and dispatch LoadMessages
    saveDataSuccessLoadMessages$ = createEffect(() =>
        this.actions$.pipe(
            ofType<SaveMessageSuccess>(MessageActionTypes.SaveMessageSuccess),
            map(action => new LoadMessages({ applicationId: action.payload.applicationId, clientId: action.payload.clientId, deviceId: action.payload.deviceId, bypassCache: action.payload.bypassCache }))
        )
    );

    saveDataSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType<SaveMessageSuccess>(MessageActionTypes.SaveMessageSuccess),
            map(action => new LoadMessages({ applicationId: action.payload.applicationId, clientId: action.payload.clientId, deviceId: action.payload.deviceId, bypassCache: true }))
        )
    );

    deleteMessage$ = createEffect(() =>
        this.actions$.pipe(
            ofType<DeleteMessage>(MessageActionTypes.DeleteMessage),
            mergeMap(action =>
                this.backendService.deleteMessage(action.payload.messageId, action.payload.bypassCache).pipe(
                    map(() => new DeleteMessageSuccess()),
                    catchError(error => of(new DeleteMessageFailure({ error })))
                )
            )
        )
    );

    // Ensure that all your effect functions return Observables of Actions

    scrollToTop$ = createEffect(() =>
        this.actions$.pipe(
            ofType<SaveMessageSuccess>(MessageActionTypes.SaveMessageSuccess),
            map(() => new ScrollToTopAction())
        )
    );
}