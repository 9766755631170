import { MessageActions, MessageActionTypes } from "./../actions/message.actions";
import { initialMessageState, MessageState } from "../states/message.states";
import { Utility } from '../../../../_shared/utils';

export function messageReducer(state = initialMessageState, action: MessageActions): MessageState
{
    switch (action.type)
    {
        case MessageActionTypes.SelectMessage:
            return {
                ...state,
                selectedMessageId: action.payload.messageId, // Or selectedMessage: action.payload.message
            };
        case MessageActionTypes.DeselectMessage:
            return {
                ...state,
                selectedMessageId: null, // Or selectedMessage: null
            };

        case MessageActionTypes.LoadMessages:
        case MessageActionTypes.LoadMessageById:
        case MessageActionTypes.LoadMessageByUId: {
            return {
                ...state,
                loading: true,
                error: null
            };
        }
        case MessageActionTypes.LoadMessagesSuccess: {
            return {
                ...state,
                messages: action.payload.messages,
                loading: false
            };
        }
        case MessageActionTypes.LoadMessageByIdSuccess: {
            return {
                ...state,
                selectedMessage: action.payload.message,
                message: Utility.detectAndConvert(action.payload.message),
                messages: state.messages ? [...state.messages, action.payload.message] : null,
                loading: false
            };
        }
        case MessageActionTypes.LoadMessageByUIdSuccess: {
            return {
                ...state,
                selectedMessageId: Utility.detectAndConvert(action?.payload?.message).Id || null, // Set the selectedMessageId
                selectedMessage: Utility.detectAndConvert(action.payload.message),// Set the selectedMessage directly as well
                message: Utility.detectAndConvert(action.payload.message),
                messages: state.messages ? [...state.messages, Utility.detectAndConvert(action.payload.message)] : [Utility.detectAndConvert(action.payload.message)],
                loading: false
            };
        }
        case MessageActionTypes.LoadMessagesFailure:
        case MessageActionTypes.LoadMessageByIdFailure:
        case MessageActionTypes.LoadMessageByUIdFailure: {
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        }

        case MessageActionTypes.LoadMessage:
        case MessageActionTypes.SaveMessage:
        case MessageActionTypes.DeleteMessage: {
            return {
                ...state,
                loading: true,
                error: null
            };
        }
        case MessageActionTypes.LoadMessageSuccess:
        case MessageActionTypes.SaveMessageSuccess: {
            return {
                ...state,
                message: action.payload.message,
                loading: false
            };
        }
        case MessageActionTypes.LoadMessageFailure:
        case MessageActionTypes.SaveMessageFailure:
        case MessageActionTypes.DeleteMessageFailure: {
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };
        }
        case MessageActionTypes.DeleteMessageSuccess: {
            return {
                ...state,
                message: null,
                loading: false
            };
        }
        case MessageActionTypes.AddMessageFromSignalR:
            return {
                ...state,
                messages: state.messages ? [...state.messages, action.payload.message] : [action.payload.message]
            };
        case MessageActionTypes.UpdateMessageSuccess: {
            return {
                ...state,
                messages: state.messages ? state.messages.map(message =>
                    message.Id === action.payload.message.Id ? { ...message, ...action.payload.message } : message
                ) : null,
                loading: false,
                error: null,
            };
        }

        case MessageActionTypes.UpdateMessageFailure: {
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        }
        default:
            return state;
    }
}