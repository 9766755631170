import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { ApiHttpOptions } from '../_models/core/api-http-options.model';
import { PageStateService } from './page-state.service';
import { AppConfig } from '../_configuration/configuration';
import { displayLoading } from '../_interceptors/http-config.interceptor';

const config = AppConfig.getInstance();

@Injectable({
    providedIn: 'root'
})
export class ApiService
{
    private response: any;

    constructor(private http: HttpClient, private pageStateService: PageStateService) 
    {

    }

    run(options: ApiHttpOptions): Observable<any>
    {
        return this.pageStateService.shouldSkipRequest(['/login', '/registration'], options.forceRequest).pipe(switchMap((shouldSkip) =>
        {
            if (shouldSkip && !options.forceRequest)
                return of(null);

            let http = this.http;

            const gatewayEndpoint: string = config.webApiBaseUrl + "/v1/gateway";
            const finalUrl = gatewayEndpoint;

            let commonHeaders: HttpHeaders = new HttpHeaders();

            const applicationId: string = localStorage.getItem("application_id") || "";
            const clientId: string = localStorage.getItem("client_id") || "";
            const deviceId: string = localStorage.getItem("device_id") || "";

            commonHeaders = commonHeaders.append("applicationid", applicationId);
            commonHeaders = commonHeaders.append("clientid", clientId);
            commonHeaders = commonHeaders.append("deviceid", deviceId);

            return http.post(finalUrl, options, { headers: commonHeaders, context: displayLoading(options.enableLoading) });
        }));
    }

    public runWithOptions(options: ApiHttpOptions): Observable<any>
    {
        if (options == null)
            return EMPTY;

        try
        {
            return this.run(options).pipe(map(data =>
            {
                if (!data || data == null)
                    return EMPTY;

                this.response = data.Payload?.Response?.Response?.Response;

                return this.response;

            }), catchError((error) => this.handleLoggedError(error, options)));
        }
        catch (e)
        {
            return EMPTY;
        }
    }

    handleLoggedError(error: Response, options: ApiHttpOptions)
    {
        return throwError(() => error);
    }
}
