import { jwtDecode } from 'jwt-decode';

export function decodeToken(token: string): any
{
    try
    {
        return jwtDecode(token);
    }
    catch (Error)
    {
        return null;
    }
}

export function isTokenExpired(decodedToken: any): boolean
{
    try
    {
        if (decodedToken.exp === undefined)
            // If there is no expiration time, token is considered expired
            return true;

        const currentTime = Math.floor(Date.now() / 1000);
        const isExpired: boolean = currentTime >= decodedToken.exp;

        return isExpired;
    }
    catch (error)
    {
        // Token decoding failed, consider it as expired
        return true;
    }
}