import { Injectable, ViewChild } from '@angular/core';
import { AlertController, IonContent, ModalController } from '@ionic/angular';
import { ModalTemplateComponent } from './../_shared/modal-template/detail.component';
import { ModalOptions } from './../_models/modal-options.model';
import { AlertOptions } from './../_models/alert-options.model';

@Injectable({
    providedIn: 'root'
})
export class ModalService
{
    public options?: ModalOptions;

    constructor(public alertController: AlertController, private modalController: ModalController) 
    {

    }

    async open(options?: ModalOptions)
    {
        if (options == null && this.options != null)
            options = this.options;

        if (options == null || options.componentRef == null)
        {
            this.alert({ header: "Modal error", message: "Modal options are null or invalid." });

            return null;
        }

        if (options.params == null)
            options.params = {};

        if (options.componentInstance != null)
            options.params = options.componentInstance;

        if (options.params.componentRef == null)
            options.params.componentRef = options.componentRef;

        options.params.ActiveModal = this.modalController;

        const modal = await this.modalController.create({
            id: 'spoon-indicator-3',
            component: options.component || ModalTemplateComponent,
            componentProps: { data: options.params },
            cssClass: options.cssClass || "modal-fullscreen"
        });

        const scrollElement = await options?.content?.getScrollElement();
        const scrollPosition = scrollElement?.scrollTop || 0;

        modal.onWillDismiss().then(
            (result) =>
            {
                let data = result.data;

                if (!data)
                    data = result;

                if (options?.beforeClose)
                    options.beforeClose(data);
            });

        modal.onDidDismiss().then(
            (result) =>
            {
                let data = result.data;

                if (!data)
                    data = result;

                if (options?.close)
                    options.close(data);

                modal.dismiss(data);

                // Restore the scroll position after the modal is dismissed
                if (options?.keepScroll)
                    scrollElement?.scrollTo(0, scrollPosition);
            });

        return await modal.present();
    }

    async close(modal: ModalController, data?: any, role?: string, id?: string)
    {
        if (!modal)
            return null;

        return await modal.dismiss(data, role, id || 'spoon-indicator-3');
    }

    basicAlert(message: string, cssClass: string = "")
    {
        try
        {
            if (message == null || message == "")
                return null;

            const options = new AlertOptions();

            options.message = message.split("\r\n").join("");

            if (this.isNotNullOrEmpty(cssClass))
                options.cssClass = cssClass;

            return this.alert(options);
        }
        catch (e)
        {
            return null;
        }
    }

    alert(options: AlertOptions)
    {
        const buttons: any[] = [];

        buttons.push({
            id: 'alert-ok-button',
            text: options.okButtonText,
            handler: (ok: any) =>
            {
                if (options.successFunc)
                    options.successFunc();
            }
        });

        if (options.cancelButtonText != null && options.cancelButtonText !== "")
            buttons.push({
                id: 'alert-cancel-button',
                text: options.cancelButtonText,
                role: 'cancel',
                cssClass: 'secondary',
                handler: (cancel: any) =>
                {
                    if (options.cancelFunc)
                        options.cancelFunc();
                }
            });

        const alert = this.alertController.create({
            id: 'alert-1',
            header: options.header,
            message: options.message,
            buttons: buttons,
            cssClass: options.cssClass
        });

        alert.then(o => o.present());
    }

    quickAlert(headerText: string, messageText: string, okButtonText: string, cancelButtonText: string, successFunc: any, cancelFunc: any)
    {
        const options = new AlertOptions();

        options.header = headerText;
        options.message = messageText;
        options.successFunc = () => successFunc;
        options.cancelFunc = () => cancelFunc;
        options.okButtonText = okButtonText;
        options.cancelButtonText = cancelButtonText;

        this.alert(options);
    }

    private isNullOrEmpty(value: any): boolean
    {
        return value == null || !(typeof (value) === "string") || value === "";
    }

    private isNotNullOrEmpty(value: any): boolean
    {
        return !this.isNullOrEmpty(value);
    }
}