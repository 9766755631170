import { HttpContext, HttpContextToken, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, EMPTY, finalize, map, Observable } from 'rxjs';
import { AuthenticationService } from '../_services/iam/authentication.service';
import { LoadingService } from '../_services/loading.service';

const DISPLAY_LOADING = new HttpContextToken<boolean>(() => true);

export function displayLoading(loadingValue: boolean)
{
    return new HttpContext().set(DISPLAY_LOADING, loadingValue);
}

@Injectable({
    providedIn: 'root'
})
export class HttpConfigInterceptor implements HttpInterceptor
{
    constructor(private loadingService: LoadingService, private authenticationService: AuthenticationService)
    {

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        const isLoadingServiceEnabled: boolean = request.context.get(DISPLAY_LOADING);
        const token = this.authenticationService.getAuthToken();

        if (!token || token === "" || token === "null" || token === "undefined" || token == null || token == undefined)
        {
            if (isLoadingServiceEnabled)
                this.loadingService.hide();

            this.authenticationService.logout();
        }

        if (token && !request.headers.has('Authorization'))
            request = request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });

        if (!request.headers.has('Content-Type'))
            request = request.clone({ setHeaders: { 'Content-Type': 'application/json' } });

        if (!request.headers.has('Accept'))
            request = request.clone({ setHeaders: { 'Accept': 'application/json' } });

        if (isLoadingServiceEnabled)
            this.loadingService.show();

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) =>
            {
                return event;
            }),
            catchError((error: HttpErrorResponse) =>
            {
                if (isLoadingServiceEnabled)
                    this.loadingService.hide();

                if (error instanceof HttpErrorResponse && (error.status === 401 || error.status === 403))
                {

                }

                return EMPTY;
            }),
            finalize(() =>
            {
                request.context.delete(DISPLAY_LOADING);
                request.context.set(DISPLAY_LOADING, true);

                if (isLoadingServiceEnabled)
                    this.loadingService.hide();
            })
        );
    }
}
