import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, BehaviorSubject } from 'rxjs';
import { Menu } from '../_models/data-store/menu';
import { LoadMenu } from '../_ngrx-store/menu/actions/menu.actions';
import { selectMenu } from '../_ngrx-store/menu/selectors/menu.selectors';

@Injectable({
    providedIn: 'root'
})
export class MenuService
{
    private appPagesSubject = new BehaviorSubject<Menu>(null!);
    appPages$: Observable<Menu> = this.appPagesSubject.asObservable();

    constructor(private store: Store)
    {
        this.store.pipe(select(selectMenu)).subscribe((menu: Menu) =>
        {
            this.appPagesSubject.next(menu);
        });
    }

    loadMenu(applicationId: string | null, clientId: string | null, deviceId: string | null)
    {
        this.store.dispatch(new LoadMenu({ applicationId, clientId, deviceId, sectionId: 0, bypassCache: false, enableLoading: false }));
    }

    clearMenu()
    {
        this.appPagesSubject.next(null!);
    }
}
