import { environment } from "src/environments/environment";

export class AppConfig
{
    private static devConfig: EnvironmentConfig = {
        webApiBaseUrl: "https://localhost:5003/api",
        signalREndpoint: "https://localhost:5003/chatHub",
        env: "dev",
        clientId: "0bca0ce7954cf2f4395c68be88b9b66c",
        clientSecret: "dlVuQkY4NlAyNUJ3MkFNdXZUbXRZR0ZLRUEwN3ZXMnE0N3RBcEs3RWNSOD06MGJjYTBjZTc5NTRjZjJmNDM5NWM2OGJlODhiOWI2NmM="
    }
    private static prodConfig: EnvironmentConfig = {
        webApiBaseUrl: "https://api.v1.frontgates.dev/api",
        signalREndpoint: "https://api.v1.frontgates.dev/chatHub",
        env: "prod",
        clientId: "0bca0ce7954cf2f4395c68be88b9b66c",
        clientSecret: "dlVuQkY4NlAyNUJ3MkFNdXZUbXRZR0ZLRUEwN3ZXMnE0N3RBcEs3RWNSOD06MGJjYTBjZTc5NTRjZjJmNDM5NWM2OGJlODhiOWI2NmM="
    }

    private static endpoints: any = {
        "ionic:localhost": environment.env === "dev" ? AppConfig.devConfig : AppConfig.prodConfig,
        "capacitor:localhost": environment.env === "dev" ? AppConfig.devConfig : AppConfig.prodConfig,
        "ionic://localhost": environment.env === "dev" ? AppConfig.devConfig : AppConfig.prodConfig,
        "capacitor://localhost": environment.env === "dev" ? AppConfig.devConfig : AppConfig.prodConfig,

        "localhost": environment.env === "dev" ? AppConfig.devConfig : AppConfig.prodConfig,
        "localhost:8100": environment.env === "dev" ? AppConfig.devConfig : AppConfig.prodConfig,

        'emaildownloader.frontgates.dev': AppConfig.prodConfig
    };

    private constructor()
    {

    }

    private static getEnvironment: any = () =>
    {
        let env: any = AppConfig.prodConfig;

        if (window.location.protocol.indexOf("http") > -1)
            env = AppConfig.endpoints[window.location.host];

        if (environment.env === "dev")
            env = AppConfig.devConfig;

        if (environment.env === "prod")
            env = AppConfig.prodConfig;

        return env;
    };

    public static Current: EnvironmentConfig = AppConfig.getEnvironment();

    // Method to get the singleton instance
    public static getInstance(): EnvironmentConfig
    {
        // if (!AppConfig.instance)
        //     AppConfig.instance = new AppConfig();

        // return AppConfig.instance;
        return this.Current;
    }
}

export class EnvironmentConfig
{
    webApiBaseUrl!: string;
    env!: string;
    clientId!: string;
    clientSecret!: string;
    signalREndpoint!: string;
}