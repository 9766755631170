import { createSelector, createFeatureSelector } from '@ngrx/store';
import { Menu } from 'src/app/_models/data-store/menu';
import { MenuState } from '../states/menu.states';

export const selectMenusState = createFeatureSelector<MenuState>('menu');
export const selectMenuState = createFeatureSelector<MenuState>('menu');

export const selectSelectedMenu = createSelector(
    selectMenuState,
    (state: MenuState) =>
    {
        if (!state.selectedMenuId)
        {
            return null;
        }
        const record = state.menus?.find(menu => menu.Id === state.selectedMenuId);

        return record;
    }
);

export const selectMenus = createSelector(
    selectMenusState,
    state => state.menus || [] as Menu[]
);

export const selectMenu = createSelector(
    selectMenuState,
    state => state.menu || {} as Menu
);

export const selectMenuLoading = createSelector(
    selectMenuState,
    state => state.loading
);

export const selectMenuError = createSelector(
    selectMenuState,
    state => state.error
);
