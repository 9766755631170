import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError, tap } from 'rxjs/operators';
import { PopService } from 'src/app/_services/data-store/extended/configurations/pop/pop.service';
import { PopActionTypes, SavePop, SavePopSuccess, SavePopFailure, DeletePop, DeletePopSuccess, DeletePopFailure, LoadPopsFailure, LoadPopById, LoadPopByIdFailure, LoadPopByIdSuccess, LoadPops, LoadPopsSuccess, ScrollToTopAction } from './../actions/pop.actions';

@Injectable()
export class PopEffects
{
    constructor(private actions$: Actions, private backendService: PopService, private router: Router) 
    {

    }

    loadPops$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadPops>(PopActionTypes.LoadPops),
            mergeMap(action =>
                this.backendService.getPops(action.payload.applicationId, action.payload.clientId, action.payload.deviceId, action.payload.bypassCache).pipe(
                    map(pops => new LoadPopsSuccess({ pops, bypassCache: action.payload.bypassCache })),
                    catchError(error => of(new LoadPopsFailure({ error })))
                )
            )
        )
    );

    loadPopById$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadPopById>(PopActionTypes.LoadPopById),
            mergeMap(action =>
                this.backendService.getPopById(action.payload.popId, action.payload.bypassCache).pipe(
                    map(pop => new LoadPopByIdSuccess({ pop, bypassCache: action.payload.bypassCache })),
                    catchError(error => of(new LoadPopByIdFailure({ error })))
                )
            )
        )
    );

    savePop$ = createEffect(() =>
        this.actions$.pipe(
            ofType<SavePop>(PopActionTypes.SavePop),
            mergeMap(action =>
                this.backendService.savePop(action.payload.pop, action.payload.bypassCache).pipe(
                    map(pop => new SavePopSuccess({ pop, applicationId: action.payload.applicationId, clientId: action.payload.clientId, deviceId: action.payload.deviceId, bypassCache: action.payload.bypassCache })),
                    catchError(error => of(new SavePopFailure({ error })))
                )
            )
        )
    );

    // Effect to handle SavePopSuccess and dispatch LoadPops
    saveDataSuccessLoadPops$ = createEffect(() =>
        this.actions$.pipe(
            ofType<SavePopSuccess>(PopActionTypes.SavePopSuccess),
            map(action => new LoadPops({ applicationId: action.payload.applicationId, clientId: action.payload.clientId, deviceId: action.payload.deviceId, bypassCache: action.payload.bypassCache }))
        )
    );

    saveDataSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType<SavePopSuccess>(PopActionTypes.SavePopSuccess),
            map(action => new LoadPops({ applicationId: action.payload.applicationId, clientId: action.payload.clientId, deviceId: action.payload.deviceId, bypassCache: true }))
        )
    );

    deletePop$ = createEffect(() =>
        this.actions$.pipe(
            ofType<DeletePop>(PopActionTypes.DeletePop),
            mergeMap(action =>
                this.backendService.deletePop(action.payload.popId, action.payload.bypassCache).pipe(
                    map(() => new DeletePopSuccess()),
                    catchError(error => of(new DeletePopFailure({ error })))
                )
            )
        )
    );

    // Ensure that all your effect functions return Observables of Actions

    scrollToTop$ = createEffect(() =>
        this.actions$.pipe(
            ofType<SavePopSuccess>(PopActionTypes.SavePopSuccess),
            map(() => new ScrollToTopAction())
        )
    );
}