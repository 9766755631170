import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'orderBy'
})
export class OrderByPipe implements PipeTransform
{
    transform(value: any[] | null | undefined, propertyName: string, reverse?: boolean): any[] | null
    {
        if (!Array.isArray(value) || value.length === 0 || !propertyName)
            return null;

        const sortedItems = [...value];

        sortedItems.sort((a, b) =>
        {
            const aValue = a[propertyName];
            const bValue = b[propertyName];

            if (aValue < bValue)
            {
                return reverse ? 1 : -1;
            } else if (aValue > bValue)
            {
                return reverse ? -1 : 1;
            } else
            {
                return 0;
            }
        });

        return sortedItems;
    }
}
