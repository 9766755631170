<ion-header *ngIf="showHeader">
    <ion-toolbar color="mytheme">
        <ion-title>{{title}}</ion-title>
        <ion-buttons slot="end">
            <ion-icon name="close" slot="icon-only" (click)="dismiss()" size="large"></ion-icon>
        </ion-buttons>
    </ion-toolbar>

</ion-header>

<ion-content class="ion-padding">
    <ng-template appComponentHost></ng-template>
</ion-content>

<ion-footer *ngIf="showFooter">
    <ion-toolbar color="mytheme">
        <ion-button slot="end" class="ion-padding-right" (click)="dismiss()"><span class="white">Close</span></ion-button>
    </ion-toolbar>
</ion-footer>