import { Capacitor } from '@capacitor/core';
import { Device } from "@awesome-cordova-plugins/device/ngx";

export class DeviceInfo
{
    /** Get the version of Cordova running on the device. */
    public cordova?: string;
    /**
     * The device.model returns the name of the device's model or product. The value is set
     * by the device manufacturer and may be different across versions of the same product.
     */
    public model?: string;
    /** Get the device's operating system name. */
    public platform?: string;
    /** Get the device's Universally Unique Identifier (UUID). */
    public uuid?: string;
    /** Get the operating system version. */
    public version?: string;
    /** Get the device's manufacturer. */
    public manufacturer?: string;
    /** Whether the device is running on a simulator. */
    public isVirtual?: boolean;
    /** Get the device hardware serial number. */
    public serial?: string;
    /** Get the Android device's SDK version. (Android-only) */
    public sdkVersion?: string;
    /** Detect if app is running on a macOS desktop with Apple Silicon. */
    public isiOSAppOnMac?: string;

    /** Get the fingerprint generated by the application that represents a PayLocal unique device id */
    public fingerprint?: string;

    constructor(device?: Device, fingerprint?: string)
    {
        this.fingerprint = fingerprint;
        this.platform = Capacitor?.getPlatform() || "web";

        if (Capacitor.isNativePlatform())
        {
            this.cordova = device?.cordova;
            this.isVirtual = device?.isVirtual;
            this.isiOSAppOnMac = device?.isiOSAppOnMac;
            this.manufacturer = device?.manufacturer;
            this.model = device?.model;
            this.platform = device?.platform;
            this.sdkVersion = device?.sdkVersion;
            this.serial = device?.serial;
            this.uuid = device?.uuid;
            this.version = device?.version;
        }
    }
}