// pop.actions.ts

import { Action } from "@ngrx/store";
import { Pop } from "src/app/_models/data-store/extended/configurations/pop";

export enum PopActionTypes
{
    SelectPop = '[Pop] Select Pop',
    DeselectPop = '[Pop] Deselect Pop',
    LoadPop = '[Pop] Load Pop',
    LoadPopSuccess = '[Pop] Load Pop Success',
    LoadPopFailure = '[Pop] Load Pop Failure',
    SavePop = '[Pop] Create Pop',
    SavePopSuccess = '[Pop] Create Pop Success',
    SavePopFailure = '[Pop] Create Pop Failure',
    DeletePop = '[Pop] Delete Pop',
    DeletePopSuccess = '[Pop] Delete Pop Success',
    DeletePopFailure = '[Pop] Delete Pop Failure',

    LoadPops = '[Pop] Load Pops',
    LoadPopsSuccess = '[Pop] Load Pops Success',
    LoadPopsFailure = '[Pop] Load Pops Failure',
    LoadPopById = '[Pop] Load Pop By Id',
    LoadPopByIdSuccess = '[Pop] Load Pop By Id Success',
    LoadPopByIdFailure = '[Pop] Load Pop By Id Failure',

    ScrollToTop = '[Pop] Scroll To Top',
}

export class SelectPop implements Action
{
    readonly type = PopActionTypes.SelectPop;
    constructor(public payload: { popId: string }) { } // Or payload: { pop: Pop }
}

export class DeselectPop implements Action
{
    readonly type = PopActionTypes.DeselectPop;
}

export class LoadPops implements Action
{
    readonly type = PopActionTypes.LoadPops;
    constructor(public payload: { applicationId: string, clientId: string, deviceId: string, bypassCache: boolean }) { }
}

export class LoadPopsSuccess implements Action
{
    readonly type = PopActionTypes.LoadPopsSuccess;
    constructor(public payload: { pops: Pop[], bypassCache: boolean }) { }
}

export class LoadPopsFailure implements Action
{
    readonly type = PopActionTypes.LoadPopsFailure;
    constructor(public payload: { error: any }) { }
}

export class LoadPopById implements Action
{
    readonly type = PopActionTypes.LoadPopById;
    constructor(public payload: { popId: string, bypassCache: boolean }) { }
}

export class LoadPopByIdSuccess implements Action
{
    readonly type = PopActionTypes.LoadPopByIdSuccess;
    constructor(public payload: { pop: Pop, bypassCache: boolean }) { }
}

export class LoadPopByIdFailure implements Action
{
    readonly type = PopActionTypes.LoadPopByIdFailure;
    constructor(public payload: { error: any }) { }
}

export class LoadPop implements Action
{
    readonly type = PopActionTypes.LoadPop;
}

export class LoadPopSuccess implements Action
{
    readonly type = PopActionTypes.LoadPopSuccess;
    constructor(public payload: { pop: Pop, bypassCache: boolean }) { }
}

export class LoadPopFailure implements Action
{
    readonly type = PopActionTypes.LoadPopFailure;
    constructor(public payload: { error: any }) { }
}

export class SavePop implements Action
{
    readonly type = PopActionTypes.SavePop;
    constructor(public payload: { pop: Pop, applicationId: string, clientId: string, deviceId: string, bypassCache: boolean }) { }
}

export class SavePopSuccess implements Action
{
    readonly type = PopActionTypes.SavePopSuccess;
    constructor(public payload: { pop: Pop, applicationId: string, clientId: string, deviceId: string, bypassCache: boolean }) { }
}

export class SavePopFailure implements Action
{
    readonly type = PopActionTypes.SavePopFailure;
    constructor(public payload: { error: any }) { }
}

export class DeletePop implements Action
{
    readonly type = PopActionTypes.DeletePop;
    constructor(public payload: { popId: string, bypassCache: boolean }) { }
}

export class DeletePopSuccess implements Action
{
    readonly type = PopActionTypes.DeletePopSuccess;
}

export class DeletePopFailure implements Action
{
    readonly type = PopActionTypes.DeletePopFailure;
    constructor(public payload: { error: any }) { }
}

export class ScrollToTopAction implements Action
{
    readonly type = PopActionTypes.ScrollToTop;
}

export type PopActions =
    | LoadPop
    | LoadPopSuccess
    | LoadPopFailure
    | SavePop
    | SavePopSuccess
    | SavePopFailure
    | DeletePop
    | DeletePopSuccess
    | DeletePopFailure
    | LoadPops
    | LoadPopById
    | LoadPopsSuccess
    | LoadPopByIdSuccess
    | LoadPopsFailure
    | LoadPopByIdFailure
    | SelectPop
    | DeselectPop
    ;
