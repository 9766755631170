import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Directive()
export class AutoUnsubscribe implements OnDestroy
{
    protected ngUnsubscribe: Subject<void> = new Subject<void>();

    ngOnDestroy()
    {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}